import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_cost_monthly = (size, page, search, class_id, school_year, cost_type, section) => {
    return axiosApi
        .get(API_URL + `cost_monthly_student?limit=${size}&page=${page}&search=${search}&class_id=${class_id}&school_year=${school_year}&cost_type_id=${cost_type}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_by_class = (bodyParam) => {
    return axiosApi
        .post(API_URL + "add_cost_monthly_by_class", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_by_student = (bodyParam) => {
    return axiosApi
        .post(API_URL + "add_cost_monthly_by_student", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_cost_monthly = (student_id, school_year) => {
    return axiosApi
        .get(API_URL + `get_cost_monthly_student?student_id=${student_id}&school_year=${school_year}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_cost_monthly = (student_id, school_year, bodyParam) => {
    return axiosApi
        .put(API_URL + `cost_monthly_student?student_id=${student_id}&school_year=${school_year}`, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const delete_cost_monthly = (student_id, school_year) => {
    return axiosApi
        .delete(API_URL + `cost_monthly_student?student_id=${student_id}&school_year=${school_year}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_section = (section) => {
    return axiosApi
        .get(API_URL + `get_section?section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_receive_post = () => {
    return axiosApi
        .get(API_URL + `all_reception_post`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const copy_cost_type = (bodyParam) => {
    return axiosApi
        .post(API_URL + "copy_cost_type_student", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_class = (section_name, classId, type) => {
    return axiosApi
        .get(API_URL + "get_class?section_name=" + section_name + "&class_id=" + classId + "&type=" + type, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const costType = {
    all_cost_monthly,
    add_by_class,
    add_by_student,
    view_cost_monthly,
    edit_cost_monthly,
    delete_cost_monthly,
    get_section,
    get_receive_post,
    copy_cost_type,
    get_class,
    API_URL,
};

export default costType;