import React, { useEffect, useState, useRef, act } from "react";
import { Container, Input, Row, Col, Card, CardHeader, CardBody, Label, Form, Modal, Table, CardFooter } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import studentPayments from "../../services/StudentPaymentService";
import system from "../../services/SystemService"
import { ModalConfirm, ModalConfirmation, ModalInfo } from "../../components/Common/ModalCustoms";
import Flatpickr from "react-flatpickr"
import DataTableContainer from "../../components/Common/DataTableContainer";

const StudentPayments = () => {
    const printRef = useRef();
    const [list_bulanan, setListBulanan] = useState([])
    const [list_non_bulanan, setListNonBulanan] = useState([])
    const [list_last_trx, setListLastTrx] = useState([])
    const [get_method, setMethod] = useState([])
    const [detail, setDetailCostStudent] = useState({})
    const [isVisible, setIsVisible] = useState(false)
    const [data_history, setDataHistory] = useState({})
    const [school_data, setSchoolData] = useState({})
    const [payment_modal, setpayment_modal] = useState(false)
    const [delete_modal, setdelete_modal] = useState(false)
    const [pay_non_monthly_modal, setpay_non_mothly_modal] = useState(false)
    const [detail_list_history_monthly, setDetailListHistoryMonthly] = useState([])
    const [schoolYear, setSchoolYear] = useState("");
    const [students, setStudents] = useState([]);
    const [detailStudent, setDetailStudent] = useState('');
    const [filtered_non_bulanan, setFilteredNonBulanan] = useState([])
    const [filtered_bulanan, setFilteredBulanan] = useState([])
    const [filtered_last_trx, setFilteredLastTrx] = useState([])
    const [filtered_list_current, setFilteredListCurrent] = useState([])
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))
    const [loadingRow, setLoadingRow] = useState(null);
    const [params] = useSearchParams();
    const registration_no = params.get("registration_no")
    const student_name = params.get("student_name")
    const school_year = params.get("school_year")

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    let sections = "";
    const getUserSections = () => {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        if (
            authUser.roles.includes("ROLE_PEMBAYARAN") &&
            !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
        ) {
            return authUser.sections[0];
        }
        return "";
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat("id-ID").format(number);
    };

    const formatToRupiah = (value) => {
        const valueString = value ? value.toString() : '';

        const numberString = valueString.replace(/\D/g, '');
        const formatted = new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
        }).format(numberString);

        return formatted.replace("Rp", "").trim();
    };

    function handleClickClose(action) {
        if (action === "payment") {
            setpayment_modal(false)
        } else if (action === "delete_one") {
            setdelete_modal(false)
        } else if (action === "pay_non_monthly") {
            setpay_non_mothly_modal(false)
        }
    }

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    useEffect(() => {
        const controller = new AbortController()
        const newYearFrom = parseInt(validationSearchType.values.school_year_from);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYear(schoolYear)
        if (registration_no !== null && student_name !== null && school_year !== null) {
            validationSearchType.submitForm()
        }
        return () => controller.abort()
    }, [])

    const initDataLastTransaction = async (student_id) => {
        try {
            sections = getUserSections();
            const result = await studentPayments.list_history_payment(student_id, sections)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                transaction_value: numberFormat(item.transaction_value),
                transaction_value_ori: item.transaction_value,
                change_money: numberFormat(item.change_money),
                change_money_ori: item.change_money
            }))
            setListLastTrx(formattedData)
            setFilteredLastTrx(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const initDataListCurrent = async (student_id) => {
        try {
            sections = getUserSections();
            const result = await studentPayments.list_cost_temp(student_id, sections)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                rates: numberFormat(item.rates),
            }))
            setFilteredListCurrent(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const initDatNonBulanan = async (school_year, reg_id) => {
        try {
            sections = getUserSections();
            const result = await studentPayments.list_cost_non_monthly(school_year, reg_id, sections)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                status_ori: item.status
            }))
            setListNonBulanan(formattedData)
            setFilteredNonBulanan(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const initDataBulanan = async (school_year, reg_id) => {
        try {
            sections = getUserSections();
            const result = await studentPayments.list_cost_monthly(school_year, reg_id, sections)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>
            }))
            setListBulanan(formattedData)
            setFilteredBulanan(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const rankFormatterLastTrx = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button
                    type="button"
                    className="btn btn-soft-success waves-effect btn-label waves-light"
                    onClick={() => handleClickPrint(row)}
                    disabled={loadingRow === row.id}
                    style={{ marginRight: "9px" }}
                >
                    <i className={loadingRow === row.id ? "bx bx-loader bx-spin label-icon" : "bx bx-printer label-icon"}></i>
                    {loadingRow === row.id ? "Loading..." : "Cetak Bukti"}
                </button>
                <button type="button" className="btn btn-soft-danger waves-effect waves-light" onClick={() => { handleClickDeleteOne(row.id) }}>
                    <i className="bx bx-trash font-size-16 align-middle"></i>
                </button>
            </div>
        );
    };

    const rankFormatterBulanan = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-success waves-effect btn-label waves-light" onClick={() => { handleClickDetail(row.student_id, row.school_year) }} data-toggle="modal">
                    <i className="bx bx-list-plus label-icon"></i> Pilih
                </button>
            </div>
        )
    };

    const rankFormatterNonBulanan = (row) => {
        if (row.status_ori == true) {
            return (
                <div style={{ textAlign: "left" }}>
                    <span className={`badge-soft-success me-1 badge badge-secondary badge-pill`}>
                        Sudah Bayar
                    </span>
                </div>
            );
        }
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-success waves-effect btn-label waves-light" onClick={() => { handleClickPaymentNonMonthly(row.id, row.rates_bak) }} data-toggle="modal">
                    <i className="bx bx-check-double label-icon"></i> Pilih
                </button>
            </div>
        )
    };

    const handleClickProcessPayment = async (reg_no) => {
        setLoadingRow("process_bayar");
        try {
            let regis_no
            if (reg_no === "") {
                regis_no = registration_no
            } else {
                regis_no = reg_no
            }
            sections = getUserSections();
            const result = await studentPayments.detail_payment(regis_no, schoolYear, sections)
            setpayment_modal(!payment_modal)
            validationPaymentType.resetForm()
            const authUser = JSON.parse(localStorage.getItem("authUser"));
            validationPaymentType.setFieldValue("student_id", result.data.id);
            validationPaymentType.setFieldValue("officer_name", authUser.full_name);
            validationPaymentType.setFieldValue("transaction_value", formatToRupiah(result.data.transaction_value));
            validationPaymentType.setFieldValue("paid_in_cash", formatToRupiah(result.data.paid_in_cash));
            validationPaymentType.setFieldValue("section_id", result.data.section_id);
            validationPaymentType.setFieldValue("change_money", 0);
            setDetailCostStudent(result.data)
            getMethod()
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    }

    const handleClickPaymentNonMonthly = (id, rates) => {
        setpay_non_mothly_modal(!pay_non_monthly_modal)
        validationPaymentNonMonthlyType.resetForm()
        validationPaymentNonMonthlyType.setFieldValue("id", id)
        validationPaymentNonMonthlyType.setFieldValue("rates_ori", formatToRupiah(rates))
    }

    // const processClickPayment = async (id) => {
    //     var params = {};
    //     params["id"] = id;
    //     await studentPayments.process_payment_non_monthly(params)
    //     validationSearchType.submitForm()
    // }

    const getMethod = async () => {
        try {
            sections = getUserSections();
            const result = await studentPayments.list_payment_method(sections)
            validationPaymentType.setFieldValue("payment_method", result.data[0]?.name);
            setMethod(result.data)
            setLoadingRow(null);
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const non_bulanan = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: false,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '15%'
        },
        {
            name: 'Nama Biaya',
            selector: row => row.cost_name,
            sortable: false
        },
        {
            name: 'Tahun Pelajaran',
            selector: row => row.school_year,
            sortable: false
        },
        {
            name: "Pilih Bayar",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatterNonBulanan,
            width: '25%'
        }
    ];

    const bulanan = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: false,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '15%'
        },
        {
            name: 'Nama Biaya',
            selector: row => row.cost_name,
            sortable: false
        },
        {
            name: 'Tahun Pelajaran',
            selector: row => row.school_year,
            sortable: false
        },
        {
            name: "Pilih Bayar",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatterBulanan,
            width: '25%'
        }
    ];

    const last_trx = [
        {
            name: 'Tanggal',
            selector: row => row.payment_date,
            sortable: false,
            width: '10%',
        },
        {
            name: 'No Transaksi',
            selector: row => row.no_transaction,
            sortable: false,
            width: '20%',
        },
        {
            name: 'Bayar Via',
            selector: row => row.payment_method,
            sortable: false,
            width: '10%',
        },
        {
            name: 'Petugas',
            selector: row => row.officer_name,
            sortable: false,
            width: '15%',
        },
        {
            name: 'Nilai Transaksi',
            selector: row => row.transaction_value,
            sortable: false,
            width: '12%',
        },
        {
            name: 'Kembalian',
            selector: row => row.change_money,
            sortable: false,
            width: '12%',
        },
        {
            name: "Aksi",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatterLastTrx,
            width: '20%'
        }
    ];

    const list_current = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: false,
            width: '8%',
        },
        {
            name: 'Nama Biaya',
            selector: row => row.cost_type.cost_name,
            sortable: false,
            width: '25%',
        },
        {
            name: 'Type',
            selector: row => row.cost_type.type,
            sortable: false,
            width: '25%',
        },
        {
            name: 'Bulan',
            selector: row => row.month,
            sortable: false,
            width: '25%',
        },
        {
            name: 'Tarif Bayar',
            selector: row => row.rates,
            sortable: false,
            width: '15%',
        }
    ];

    const handleClickClear = () => {
        validationSearchType.resetForm()
        setDetailStudent("")
    }

    const handleClickDetail = (student_id, school_year) => {
        window.location.replace(`/panel/payment/student-payments/monthly?student_id=${student_id}&school_year=${school_year}`)
    }

    const handleClickDeleteOne = (id) => {
        validationDeleteType.resetForm()
        validationDeleteType.setFieldValue("id", id)
        setdelete_modal(!delete_modal)
    }

    const handleClickDelete = (reg_no) => {
        let regis_no
        if (reg_no === "") {
            regis_no = registration_no
        } else {
            regis_no = reg_no
        }
        ModalConfirm(
            "Apakah anda yakin?",
            "Ingin menghapus semua data ini.",
            "warning",
            "payment",
            () => processClickDelete(regis_no),
        )
    }

    const processClickDelete = async (registration_no) => {
        try {
            sections = getUserSections();
            await studentPayments.delete_cost_temp(registration_no, sections)
            validationSearchType.submitForm()
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };


    const processPrint = () => {
        setTimeout(() => {
            const content = printRef.current;
            if (!content) {
                console.error("printRef tidak ditemukan!");
                return;
            }

            const printWindow = window.open("", "_blank");
            printWindow.document.write(`
                <html>
                    <head>
                    <title>Bukti Pembayaran (Ukuran kertas sepertiga A4)</title>
                    <style>
                        body {
                            margin: 0;
                            font-family: Arial, sans-serif;
                        }
                        .print-container {
                            width: 210mm;
                            height: 99mm;
                            margin: 0 auto;
                            border: 1px solid black;
                            box-sizing: border-box;
                        }
                    </style>
                    </head>
                    <body>${content.innerHTML}</body>
                </html>
            `);
            printWindow.document.close();
            printWindow.onload = () => {
                printWindow.print();
                printWindow.onafterprint = () => {
                    printWindow.close();
                };
            };
        }, 500);
    }

    const getSchoolData = async (section_id) => {
        try {
            const result = await system.get_school_data(section_id)
            setSchoolData(result.data)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const getDetailListHistoryPayment = async (history_id) => {
        try {
            sections = getUserSections();
            const result = await studentPayments.detail_list_history_peyment(history_id, sections)
            const formattedData = result.data.map((item, index) => ({
                ...item,
            }))
            setDetailListHistoryMonthly(formattedData)
            processPrint()
            setLoadingRow(null);
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const handleClickPrint = (response) => {
        setLoadingRow(response.id); // Set ID baris yang sedang loading
        getSchoolData(response.section_id)
        setDataHistory(response)
        getDetailListHistoryPayment(response.id)
    }

    const handleChangeRateToRupiah = (e) => {
        const { name, value } = e.target;
        const formattedValue = formatToRupiah(value);

        validationPaymentNonMonthlyType.setFieldValue(name, formattedValue);
    }

    const handleChangeWithRupiah = (e) => {
        const { name, value } = e.target;
        const formattedValue = formatToRupiah(value);

        validationPaymentType.setFieldValue(name, formattedValue);

        const paidInCash =
            name === "paid_in_cash"
                ? parseInt(removeRupiahFormat(value || "0"))
                : parseInt(removeRupiahFormat(validationPaymentType.values.paid_in_cash || "0"));

        const transactionValue =
            name === "transaction_value"
                ? parseInt(removeRupiahFormat(value || "0"))
                : parseInt(removeRupiahFormat(validationPaymentType.values.transaction_value || "0"));

        const changeMoney = paidInCash - transactionValue;

        validationPaymentType.setFieldValue("change_money", formatToRupiah(changeMoney >= "0" ? changeMoney : "0"));
    };

    const removeRupiahFormat = (value) => {
        return value.replace(/[Rp\s.,]/g, ""); // Hapus Rp, spasi, titik, dan koma
    };

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const convertRupiahInObject = (obj) => {
        const newObj = { ...obj };
        const fieldsToConvert = ['transaction_value', 'paid_in_cash', 'change_money', 'rates', 'rates_ori'];

        fieldsToConvert.forEach((field) => {
            if (newObj[field]) {
                newObj[field] = removeRupiahFormat(newObj[field]);
            }
        });

        return newObj;
    };

    const toTerbilang = (number) => {
        const satuan = [
            "",
            "Satu",
            "Dua",
            "Tiga",
            "Empat",
            "Lima",
            "Enam",
            "Tujuh",
            "Delapan",
            "Sembilan",
        ];

        const belasan = [
            "Sepuluh",
            "Sebelas",
            "Dua Belas",
            "Tiga Belas",
            "Empat Belas",
            "Lima Belas",
            "Enam Belas",
            "Tujuh Belas",
            "Delapan Belas",
            "Sembilan Belas",
        ];

        const ribuan = ["", "Ribu", "Juta", "Miliar", "Triliun"];

        if (number === 0) return "Nol";

        const convert = (num) => {
            if (num < 10) return satuan[num];
            if (num >= 10 && num < 20) return belasan[num - 10];
            if (num < 100)
                return (
                    satuan[Math.floor(num / 10)] +
                    " Puluh " +
                    satuan[num % 10]
                ).trim();
            if (num < 1000)
                return (
                    (num < 200 ? "Seratus" : satuan[Math.floor(num / 100)] + " Ratus") +
                    " " +
                    convert(num % 100)
                ).trim();
            return "";
        };

        const chunks = [];
        let i = 0;

        while (number > 0) {
            chunks.push(number % 1000);
            number = Math.floor(number / 1000);
            i++;
        }

        const result = chunks
            .map((chunk, idx) => {
                if (chunk === 0) return "";
                return (
                    convert(chunk) +
                    (chunk === 1 && idx > 0 ? " " : " ") +
                    ribuan[idx]
                ).trim();
            })
            .reverse()
            .join(" ")
            .replace(/\s+/g, " ")
            .trim();

        return result;
    };

    const handleSearch = async (search) => {
        try {
            let sections = "";
            const authUser = JSON.parse(localStorage.getItem("authUser"));
            if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
                !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
            ) {
                sections = authUser.sections[0];
            }

            const trimmedSearch = search.trim();
            if (trimmedSearch === "") {
                setStudents([]);
                return;
            } else {
                const result = await studentPayments.get_search_student(trimmedSearch, schoolYear, sections);
                setStudents(result.data);
                // setList(result.data)
            }
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    document.title = `Transaksi Pembayaran Siswa | ${webConfig[0].name} - ${webConfig[0].title}`;

    const handleSchoolYear = (event) => {
        const newYearFrom = parseInt(event.target.value);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYear(schoolYear)
        validationSearchType.setFieldValue('school_year', schoolYear);
        // initData(currentSize, currentPage, searchTerm, schoolYear)
        validationSearchType.setFieldValue('school_year_from', newYearFrom);
        validationSearchType.setFieldValue('school_year_to', parseInt(newYearFrom) + 1);
    };

    const current_year = new Date().getFullYear();
    const validationSearchType = useFormik({
        enableReinitialize: true,
        initialValues: {
            school_year_from: school_year == null ? current_year : school_year.trim().split("/")[0],
            school_year_to: school_year == null ? current_year + 1 : school_year.trim().split("/")[1],
            reg_id: ""
        },
        onSubmit: async (values, { resetForm }) => {
            let reg_id;
            if (values.reg_id === "") {
                reg_id = registration_no
            } else {
                reg_id = values.reg_id.trim().split("-")[0];
            }
            let sections = "";
            const authUser = JSON.parse(localStorage.getItem("authUser"));
            if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
                !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
            ) {
                sections = authUser.sections[0];
            }
            try {
                const getDetailStudent = await studentPayments.get_detail_student(reg_id.trim(), schoolYear, sections)
                setDetailStudent(getDetailStudent)
                initDataBulanan(schoolYear, reg_id.trim())
                initDatNonBulanan(schoolYear, reg_id.trim())
                initDataLastTransaction(getDetailStudent.data.id)
                initDataListCurrent(getDetailStudent.data.id)
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    setDetailStudent("")
                    ModalInfo(
                        "Upss!",
                        error.response.data.message,
                        "warning",
                        false
                    )
                }
            }
        }
    })

    const componentRef = useRef();

    const validationPaymentType = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            student_id: "",
            paid_in_cash: "",
            payment_date: getCurrentDate(),
            payment_method: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                const updateValues = convertRupiahInObject(values);
                if (values.paid_in_cash == 0) {
                    ModalInfo(
                        "Opps!",
                        "Bagian Pembayaran tidak boleh kosong",
                        "warning",
                        false
                    )
                    return;
                }
                await studentPayments.process_payment(updateValues)
                handleClickClose("payment")
                validationSearchType.submitForm()
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const validationDeleteType = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            description: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                console.log("Masuk Sini : " + JSON.stringify(values))
                await studentPayments.delete_payment(values)
                handleClickClose("delete_one")
                validationSearchType.submitForm()
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const validationPaymentNonMonthlyType = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            rates: "",
            rates_ori: "",
            description: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                const updateValues = convertRupiahInObject(values);
                if (parseInt(updateValues.rates) > parseInt(updateValues.rates_ori)) {
                    ModalInfo(
                        "Upss!",
                        "Jumlah bayar tidak boleh lebih besar dari jumlah sisa bayar",
                        "warning",
                        false
                    )
                } else {
                    await studentPayments.process_payment_non_monthly(updateValues)
                    handleClickClose("pay_non_monthly")
                    validationSearchType.submitForm()
                    resetForm()
                }
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Transaksi Pembayaran Siswa" />
                    <Row className="mb-3">
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                validationSearchType.handleSubmit()
                                return false;
                            }}
                        >
                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <div style={{ flex: '0 0 130px' }}>
                                    <Label className="form-label">
                                        <span style={{ color: 'red' }}>*</span> Tahun Pelajaran :
                                    </Label>
                                </div>
                                <div className="mb-2" style={{ flex: '0 0 85px' }}>
                                    <Input
                                        type="number"
                                        name="school_year_from"
                                        id="school_year_from"
                                        value={validationSearchType.values.school_year_from}
                                        onChange={handleSchoolYear}
                                        style={{ width: '85px', height: '35px' }}
                                        required
                                    />
                                </div>
                                <div className="mb-2" style={{ flex: '0 0 100px' }}>
                                    <Input
                                        type="number"
                                        name="school_year_to"
                                        id="school_year_to"
                                        value={validationSearchType.values.school_year_to}
                                        onChange={validationSearchType.handleChange}
                                        style={{ width: '85px', height: '35px' }}
                                        disabled
                                        required
                                    />
                                </div>
                                <div style={{ flex: '0 0 260px', textAlign: 'left' }}>
                                    <Label className="form-label">
                                        <span style={{ color: 'red' }}>*</span> Input Berdasarkan NIM/Nama Siswa :
                                    </Label>
                                </div>
                                <div className="mb-2 d-flex align-items-center" style={{ flex: '0 0 50px' }}>
                                    <div className="btn-group ms-2" role="group" aria-label="Button group">
                                        <Input
                                            className="form-control"
                                            list="dataListStudent"
                                            style={{ width: '350px', height: '35px', textAlign: 'left' }}
                                            id="reg_id"
                                            name="reg_id"
                                            placeholder="Masukkan NIM/Nama Siswa"
                                            value={validationSearchType.values.reg_id || (registration_no && student_name ? `${registration_no} - ${student_name}` : "")}
                                            onChange={(e) => {
                                                validationSearchType.setFieldValue('reg_id', e.target.value);
                                                handleSearch(e.target.value);
                                            }}
                                        />
                                        <datalist id="dataListStudent">
                                            {students.map((student) => (
                                                <option key={student.id} value={`${student.registration_no} - ${student.student_name}`} />
                                            ))}
                                        </datalist>
                                        <button type="submit" className="btn btn-soft-primary" style={{ height: '35px' }}>Pencarian</button>
                                        <button type="button" className="btn btn-soft-danger" onClick={handleClickClear} style={{ height: '35px' }}>Hapus</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Row>
                    {detailStudent && (
                        <div>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Informasi Siswa</h5>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <div className="pb-1">
                                            <Row>
                                                <Col xl={2}>
                                                    <div>
                                                        <span>Tahun Pelajaran</span>
                                                    </div>
                                                </Col>
                                                <div className="col-xl">
                                                    <div className="text-muted">
                                                        : {detailStudent.data.school_year || "-"}
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                        <div className="py-1">
                                            <Row>
                                                <Col xl={2}>
                                                    <div>
                                                        <span>N I M</span>
                                                    </div>
                                                </Col>
                                                <div className="col-xl">
                                                    <div className="text-muted">
                                                        : {detailStudent.data.registration_no || "-"}
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                        <div className="py-1">
                                            <Row>
                                                <Col xl={2}>
                                                    <div>
                                                        <span>Nama Siswa</span>
                                                    </div>
                                                </Col>
                                                <div className="col-xl">
                                                    <div className="text-muted">
                                                        : {detailStudent.data.student_name || "-"}
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                        <div className="py-1">
                                            <Row>
                                                <Col xl={2}>
                                                    <div>
                                                        <span>Kelas</span>
                                                    </div>
                                                </Col>
                                                <div className="col-xl">
                                                    <div className="text-muted">
                                                        : {detailStudent.data.class_name || "-"}
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Riwayat Transaksi Terakhir</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <DataTableContainer
                                                columns={last_trx}
                                                data={filtered_last_trx}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Transaksi Saat Ini</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <DataTableContainer
                                                columns={list_current}
                                                data={filtered_list_current}
                                            />
                                        </CardBody>
                                        {filtered_list_current && filtered_list_current.length > 0 ? (
                                            <>
                                                <CardFooter className="d-flex justify-content-end">
                                                    <button type="button" className="btn btn-soft-danger waves-effect btn-label waves-light me-2" onClick={() => { handleClickDelete(validationSearchType.values.reg_id.split("-")[0].trim()) }} data-toggle="modal">
                                                        <i className="bx bx-trash label-icon"></i>Hapus Semua
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="btn btn-soft-success waves-effect btn-label waves-light"
                                                        onClick={() => handleClickProcessPayment(validationSearchType.values.reg_id.split("-")[0].trim())}
                                                        disabled={loadingRow === "process_bayar"}
                                                    >
                                                        <i className={loadingRow === "process_bayar" ? "bx bx-loader bx-spin label-icon" : "bx bx-money label-icon"}></i>
                                                        {loadingRow === "process_bayar" ? "Loading..." : "Proses Bayar"}
                                                    </button>
                                                </CardFooter>
                                            </>
                                        ) : null}
                                    </Card>
                                </Col>
                            </Row>
                            <div>
                                <Row>
                                    {/* Kolom Kiri */}
                                    <Col xl={6}>
                                        <Card>
                                            <CardHeader>
                                                <h5 className="card-title mb-0">NON BULANAN</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <DataTableContainer
                                                    columns={non_bulanan}
                                                    data={filtered_non_bulanan}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    {/* Kolom Kanan */}
                                    <Col xl={6}>
                                        <Card>
                                            <CardHeader>
                                                <h5 className="card-title mb-0">BULANAN</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <DataTableContainer
                                                    columns={bulanan}
                                                    data={filtered_bulanan}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )}
                </Container>
            </div >
            <div ref={printRef} hidden>
                <table style={{ paddingBottom: "5px" }}>
                    <tr>
                        <td style={{ fontSize: "14px", fontWeight: "bold", verticalAlign: "bottom" }}>{school_data?.school_name?.toUpperCase()}</td>
                        <td style={{ border: "1px dashed black", padding: "5px", width: "135px", fontSize: "13px", textAlign: "center", fontWeight: "bold" }}>BUKTI PEMBAYARAN</td>
                    </tr>
                    <tr>
                        <td style={{ fontSize: "13px", paddingRight: "200px" }}>{school_data?.school_address} Telp : {school_data?.school_phone}<br />Email : {school_data?.addition}</td>
                        <td ></td>
                    </tr>
                </table>
                <div
                    style={{
                        border: "none",
                        borderTop: "1px dashed black",
                        width: "100%",
                    }}
                />
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                        <tr>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "12%" }}>Diterima dari</td>
                            <td colSpan="3" style={{ fontSize: "12px", textAlign: "left", width: "25%" }}>: {detailStudent?.data?.student_name}</td>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "12%" }}>Tgl. Bayar</td>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "25%" }}>: {data_history?.payment_date}</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "12%" }}>Nomor Induk</td>
                            <td colSpan="3" style={{ fontSize: "12px", textAlign: "left", width: "25%" }}>: {detailStudent?.data?.registration_no}</td>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "12%" }}>No. Bukti</td>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "25%" }}>: {data_history?.no_transaction}</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "12%" }}>Kelas</td>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "25%" }}>: {detailStudent?.data?.class_name}</td>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "12%" }}>Status Siswa</td>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "15%" }}>: {detailStudent?.data?.status}</td>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "12%" }}>Metode</td>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "25%" }}>: {data_history?.payment_method}</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "12%" }}>Terbilang</td>
                            <td colSpan="3" style={{ fontSize: "12px", textAlign: "left", width: "25%" }}>: {toTerbilang(data_history?.transaction_value_ori)} Rupiah</td>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "12%" }}>Petugas</td>
                            <td style={{ fontSize: "12px", textAlign: "left", width: "25%" }}>: {data_history?.officer_name}</td>
                        </tr>
                    </tbody>
                </table>
                <div
                    style={{
                        border: "none",
                        borderTop: "1px dashed black",
                        width: "100%",
                    }}
                />
                <p style={{ fontSize: "12px", fontWeight: "bold", margin: "2px 0" }}>Dengan rincian pembayaran sebagai berikut :</p>
                <div
                    style={{
                        border: "none",
                        borderTop: "1px dashed black",
                        width: "100%",
                    }}
                />
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                        {detail_list_history_monthly.map((detail, index) => (
                            <tr key={index}>
                                <td style={{ fontSize: "12px", width: "30%", paddingLeft: "20px" }}>{detail?.no}.&nbsp;&nbsp;&nbsp;{detail?.cost_type?.cost_name} {detail?.month ? `(` + detail.month.toUpperCase() + `)` : ""}</td>
                                <td style={{ fontSize: "12px", width: "30%" }}></td>
                                <td style={{ fontSize: "12px", textAlign: "right", width: "12%" }}>Rp.</td>
                                <td style={{ fontSize: "12px", textAlign: "right", width: "10%" }}>{formatNumber(detail?.rates)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div
                    style={{
                        border: "none",
                        borderTop: "1px dashed black",
                        width: "100%",
                    }}
                />
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                        <tr>
                            <td style={{ fontSize: "12px", textAlign: "center", width: "30%", paddingTop: "5px" }}>Penyetor,</td>
                            <td style={{ fontSize: "12px", textAlign: "center", width: "30%", paddingTop: "5px" }}>Penerima,</td>
                            <td style={{ fontSize: "12px", textAlign: "right", width: "12%", fontWeight: "bold" }}>Jumlah Rp.</td>
                            <td style={{ fontSize: "12px", textAlign: "right", width: "10%", fontWeight: "bold" }}>{formatNumber(data_history?.transaction_value_ori)}</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ fontSize: "12px", textAlign: "center", width: "30%" }}></td>
                            <td style={{ fontSize: "12px", textAlign: "right", width: "12%" }}>Pembayaran Rp.</td>
                            <td style={{ fontSize: "12px", textAlign: "right", width: "10%" }}>{formatNumber(data_history?.paid_in_cash)}</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ fontSize: "12px", textAlign: "center", width: "30%" }}></td>
                            <td style={{ fontSize: "12px", textAlign: "right", width: "12%" }}>Kembali Rp.</td>
                            <td style={{ fontSize: "12px", textAlign: "right", width: "10%" }}>{formatNumber(data_history?.change_money_ori)}</td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: "12px", textAlign: "center", width: "30%" }}>(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</td>
                            <td style={{ fontSize: "12px", textAlign: "center", width: "30%" }}>({data_history?.officer_name})</td>
                            <td colSpan={2} style={{ fontSize: "12px", width: "15%", fontWeight: "bold" }}>
                                <div
                                    style={{
                                        border: "none",
                                        borderTop: "1px dashed black",
                                        width: "100%",
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Modal
                    isOpen={payment_modal}
                    toggle={() => {
                        handleClickPayment()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Bayar
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("payment")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationPaymentType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <Table>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>N I S</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>{detail.registration_no}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Nama</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>{detail.student_name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Kelas Terakhir</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>{detail.class_name}</td>
                                    </tr>
                                    <tr style={{ height: "50px" }}>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Tanggal Transaksi</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="dd-mm-yyyy"
                                                value={validationPaymentType.values.payment_date}
                                                onChange={([selectedDate]) => {
                                                    const formattedDate = selectedDate
                                                        ? selectedDate.toLocaleDateString("id-ID").replace(/\//g, "-")
                                                        : "";
                                                    validationPaymentType.setFieldValue('payment_date', formattedDate);
                                                }}
                                                options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "d-m-Y"
                                                }}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Cara Bayar</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>
                                            <Input
                                                type="select"
                                                name="payment_method"
                                                id="payment_method"
                                                value={validationPaymentType.values.payment_method || ""}
                                                onChange={validationPaymentType.handleChange}
                                                required
                                            >
                                                {get_method.map((method, index) => (
                                                    <option value={method.name} key={index}>{method.name}</option>
                                                ))}

                                            </Input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Nilai Transaksi</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td style={{ textAlign: "right", paddingRight: "25px" }}>{validationPaymentType.values.transaction_value}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Pembayaran</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>
                                            {/* Input untuk uang yang dibayarkan */}
                                            <Input
                                                type="text"
                                                name="paid_in_cash"
                                                id="paid_in_cash"
                                                placeholder="Enter paid in cash"
                                                value={validationPaymentType.values.paid_in_cash}
                                                onChange={(e) => handleChangeWithRupiah(e)}
                                                style={{ textAlign: "right" }}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Uang Kembalian</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td style={{ textAlign: "right", paddingRight: "25px" }}>{validationPaymentType.values.change_money}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>
                                            <div className="mb-3">
                                                <Label className="form-label">Deskripsi</Label>
                                                <Input
                                                    type="textarea"
                                                    name="description"
                                                    id="description"
                                                    placeholder="Enter deskripsi"
                                                    value={validationPaymentType.values.description}
                                                    onChange={validationPaymentType.handleChange}
                                                    required
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Proses Bayar</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("payment")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={delete_modal}
                    toggle={() => {
                        handleClickDeleteOne()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Batalkan Transaksi
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("delete_one")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationDeleteType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Input
                                    type="text"
                                    name="id"
                                    id="id"
                                    placeholder="Enter id"
                                    value={validationDeleteType.values.id || ''}
                                    onChange={validationDeleteType.handleChange}
                                    hidden
                                    required
                                />
                                <Label className="form-label">Berikan Alasan Pembatalan :</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    placeholder="Enter Deskripsi"
                                    value={validationDeleteType.values.description || ''}
                                    onChange={validationDeleteType.handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-danger waves-effect btn-label waves-light" type="submit"><i className="bx bx-trash label-icon"></i> Proses Delete</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("delete_one")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={pay_non_monthly_modal}
                    toggle={() => {
                        handleClickPaymentNonMonthly()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Bayar Non Bulanan
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("pay_non_monthly")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationPaymentNonMonthlyType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Input
                                    type="text"
                                    name="id"
                                    id="id"
                                    placeholder="Enter id"
                                    value={validationPaymentNonMonthlyType.values.id || ''}
                                    onChange={validationPaymentNonMonthlyType.handleChange}
                                    hidden
                                    required
                                />
                                <Label className="form-label">Jumlah Sisa Bayar :</Label>
                                <Input
                                    type="text"
                                    name="rates_ori"
                                    id="rates_ori"
                                    placeholder="Enter rates"
                                    value={validationPaymentNonMonthlyType.values.rates_ori || ''}
                                    onChange={(e) => handleChangeRateToRupiah(e)}
                                    disabled
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Jumlah Bayar :</Label>
                                <Input
                                    type="text"
                                    name="rates"
                                    id="rates"
                                    placeholder="Enter rates"
                                    value={validationPaymentNonMonthlyType.values.rates || ''}
                                    onChange={(e) => handleChangeRateToRupiah(e)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Keterangan :</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    placeholder="Enter Deskripsi"
                                    value={validationPaymentNonMonthlyType.values.description || ''}
                                    onChange={validationPaymentNonMonthlyType.handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-success waves-effect btn-label waves-light" type="submit"><i className="bx bx-check-double label-icon"></i> Bayar</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("pay_non_monthly")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
            </div>
        </React.Fragment >
    )
};

export default StudentPayments;
