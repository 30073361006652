import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import { Row, Col, Container, Form, Input, FormFeedback, UncontrolledAlert, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { apiError, apiSuccess, loginSuccess, loginUser } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-sm.png"

//Import config
import CarouselPage from "./CarouselPage"
import { createSelector } from "reselect";

import authObject from "../../services/AuthService"
import configObject from "../../services/ConfigService";

const Login = props => {

  const [passwordShow, setPasswordShow] = useState(false);

  const dispatch = useDispatch()

  const errorData = createSelector(
    (state) => state.Login,
    (loginState) => ({
      error: loginState.error,
      messages: loginState.messages
    })
  );

  // Inside your component
  const { error, messages } = useSelector(errorData);

  useEffect(() => {
    if (localStorage.getItem("authUser") !== null) {
      window.location.replace('/panel/dashboard')
    } else if (localStorage.getItem("webConfig") == null) {
      configObject.config().then(
        (data) => {
          localStorage.setItem("webConfig", JSON.stringify(data));
          window.location.reload()
        }
      )
    }
  })

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch(apiError(""));
        dispatch(apiSuccess(""))
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error, dispatch]);

  const webConfig = JSON.parse(localStorage.getItem("webConfig"))


  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "" || '',
      password: "" || '',
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      authObject.login(values, props).then(
        (data) => {
          dispatch(apiSuccess("Login Success"));
          const timer = setTimeout(() => {
            if (data.accessToken) {
              const loginTime = new Date().getTime();
              const authUser = { ...data, loginTime };
              localStorage.setItem("authUser", JSON.stringify(authUser));
              window.location.replace('/panel/dashboard');
            }
            return Promise.resolve();
          }, 1000);

          return () => clearTimeout(timer);
        },
        (error) => {
          const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          dispatch(apiError(message));
        }
      )
    }
  });

  document.title = `Login | ${webConfig ? webConfig[0].name : "-"} - ${webConfig ? webConfig[0].title : "-"}`;

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="28" /> <span className="logo-txt">{webConfig == null ? "-" : webConfig[0].name}</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">Sign in to continue to {webConfig == null ? "-" : webConfig[0].name}.</p>
                      </div>
                      {error ? (
                        <UncontrolledAlert color="danger" className="alert-label-icon label-arrow">
                          <i className="mdi mdi-block-helper label-icon"></i>{typeof error === 'string' ? error : error.message || 'Unknown error'}
                        </UncontrolledAlert>
                      ) : null}

                      {messages ? (
                        <UncontrolledAlert color="success" className="alert-label-icon label-arrow">
                          <i className="mdi mdi-checkbox-marked-circle-outline label-icon"></i>{typeof messages === 'string' ? messages : messages || 'Unknown error'}
                        </UncontrolledAlert>
                      ) : null}
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Label className="form-label">Password</Label>
                            </div>
                          </div>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light shadow-none ms-0" type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()} {webConfig == null ? "-" : webConfig[0].name} . Crafted with <i className="mdi mdi-heart text-danger"></i></p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}