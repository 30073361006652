import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [full_name, setFullName] = useState('');

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setFullName(obj.full_name);
    }
  }, [props.success]);

  const handleClickLogout = () => {
    localStorage.removeItem("authUser");
    window.location.href = "/panel/login";
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 fw-medium">{full_name}</span>&nbsp;&nbsp;&nbsp;
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">

          <Link to={"/profile"} className="dropdown-item">
            <i className="mdi mdi mdi-face-man font-size-16 align-middle me-1"></i>{" "}
            {props.t("Profile")}{" "}
          </Link>{" "}

          <Link to="/page-lock-screen" className="dropdown-item">
            <i className="mdi mdi-lock font-size-16 align-middle me-1"></i>
            {props.t("Lock screen")}
          </Link>

          <div className="dropdown-divider" />
          <a href="#" onClick={() => handleClickLogout()} className="dropdown-item">
            <i className="mdi mdi-logout font-size-16 align-middle me-1"></i>
            <span>{props.t("Logout")}</span>
          </a>

        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)
  );