import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, Card, Row, Col, CardHeader, CardBody, Table, Modal, Form, Label, Input } from "reactstrap";
import studentPayments from "../../services/StudentPaymentService";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr"
import { ModalConfirmation } from "../../components/Common/ModalCustoms";
import DataTableContainer from "../../components/Common/DataTableContainer";

import Breadcrumbs from "../../components/Common/Breadcrumb";
const MonthlyPayments = () => {
    const [list, setList] = useState([])
    const [get_method, setMethod] = useState([])
    const [detail, setDetailCostStudent] = useState({})
    const [payment_modal, setpayment_modal] = useState(false)
    const [params] = useSearchParams();
    const student_id = params.get("student_id")
    const school_year = params.get("school_year")
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))
    const [selectedRows, setSelectedRows] = useState([]);

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    let sections = "";
    const getUserSections = () => {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        if (
            authUser.roles.includes("ROLE_PEMBAYARAN") &&
            !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
        ) {
            return authUser.sections[0];
        }
        return "";
    };

    useEffect(() => {
        const controller = new AbortController()
        initData(student_id, school_year)
        detailCostStudent(student_id, school_year)
        return () => controller.abort()
    }, [])

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (student_id, school_year) => {
        try {
            sections = getUserSections();

            const result = await studentPayments.all_cost_monthly_detail(student_id, school_year, sections)
            const formattedData = result.data.map((item, index) => {
                const updatedData = {
                    ...item,
                    rates: numberFormat(item.rates),
                    rates_ori: item.rates,
                    status_ori: item.status
                };

                if (item.status) {
                    updatedData.status = <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Sudah Bayar </span>;
                } else {
                    updatedData.status = <span className='badge-soft-danger me-1 badge badge-secondary badge-pill'> Belum Bayar </span>;
                }

                return updatedData;
            });

            setList(formattedData)

        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const detailCostStudent = async (student_id, school_year) => {
        try {
            sections = getUserSections();
            const result = await studentPayments.detail_cost_student(student_id, school_year, sections)
            setDetailCostStudent(result.data)

        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const getMethod = async () => {
        try {
            sections = getUserSections();
            const result = await studentPayments.list_payment_method(sections)
            validationPaymentType.setFieldValue("payment_method", result.data[0]?.name);
            setMethod(result.data)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const rankFormatter = (row) => {
        if (row.status_ori == true) {
            return null;
        }
        return (
            <div style={{ textAlign: "left" }}>
                <button
                    type="button"
                    className={`btn btn-soft-success waves-effect btn-label waves-light ${selectedRows.includes(row.id) ? "active" : ""
                        }`}
                    onClick={() => handleClickPayment(row.id, row.month)}
                    data-toggle="modal"
                >
                    <i className="bx bx-check-double label-icon"></i>
                    {selectedRows.includes(row.id) ? "Dipilih" : "Pilih"}
                </button>
            </div>
        )
    };

    const columns = [
        {
            name: 'Bulan',
            selector: row => row.month,
            sortable: true,
        },
        {
            name: 'Biaya',
            selector: row => row.rates,
            sortable: true,
        },
        {
            name: 'Status Bayar',
            selector: row => row.status,
            sortable: true,
            width: '22%',
        },
        {
            name: "Pilih Bayar",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '25%',
        }
    ];

    document.title = `Transaksi Pembayaran Siswa | ${webConfig[0].name} - ${webConfig[0].title}`;

    const handleClickBack = () => {
        window.location.replace('../student-payments?registration_no=1231141&student_name=Muhammad Sholihin&school_year=2024/2025')
    };

    const formatToRupiah = (value) => {
        const valueString = value ? value.toString() : '';

        const numberString = valueString.replace(/\D/g, '');
        const formatted = new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
        }).format(numberString);

        return formatted.replace("Rp", "").trim();
    };

    const removeRupiahFormat = (value) => {
        return value.replace(/[Rp\s.,]/g, ""); // Hapus Rp, spasi, titik, dan koma
    };

    const handleClickPayment = (id, month) => {
        ModalConfirmation(
            "Apakah anda yakin?",
            "Ingin menambahkan pembayaran bulan " + month,
            "warning",
            "payment",
            () => processClickPayment(id),
        )
    }

    const processClickPayment = async (id) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
        } else {
            try {
                var params = { id };
                await studentPayments.process_payment_monthly(params);
                setSelectedRows([...selectedRows, id]);
            } catch (error) {
                console.error("Error processing payment:", error);
            }
        }
    }

    function handleClickClose(action) {
        if (action === "payment") {
            setpayment_modal(false)
        }
    }

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0'); // Tambahkan 0 jika kurang dari 10
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Bulan dimulai dari 0
        const year = today.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleChangeWithRupiah = (e) => {
        const { name, value } = e.target;
        const formattedValue = formatToRupiah(value);

        validationPaymentType.setFieldValue(name, formattedValue);

        const paidInCash =
            name === "paid_in_cash"
                ? parseInt(removeRupiahFormat(value || "0"))
                : parseInt(removeRupiahFormat(validationPaymentType.values.paid_in_cash || "0"));

        const transactionValue =
            name === "transaction_value"
                ? parseInt(removeRupiahFormat(value || "0"))
                : parseInt(removeRupiahFormat(validationPaymentType.values.transaction_value || "0"));

        const changeMoney = paidInCash - transactionValue;

        validationPaymentType.setFieldValue("change_money", formatToRupiah(changeMoney >= "0" ? changeMoney : "0"));
    };

    const convertRupiahInObject = (obj) => {
        const newObj = { ...obj }; // Salin objek untuk menghindari mutasi
        const fieldsToConvert = ['transaction_value', 'paid_in_cash', 'change_money']; // Daftar properti yang akan dikonversi

        fieldsToConvert.forEach((field) => {
            if (newObj[field]) {
                newObj[field] = removeRupiahFormat(newObj[field]);
            }
        });

        return newObj;
    };

    const validationPaymentType = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            student_id: "",
            paid_in_cash: "",
            payment_date: getCurrentDate(),
            payment_method: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                const updateValues = convertRupiahInObject(values);
                console.log("VALUES : " + JSON.stringify(updateValues))
                if (values.paid_in_cash == 0) {
                    ModalInfo(
                        "Opps!",
                        "Bagian Pembayaran tidak boleh kosong",
                        "warning",
                        false
                    )
                    return;
                }
                await studentPayments.process_payment_monthly(updateValues)
                handleClickClose("payment")
                initData(student_id, school_year)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Pembayaran Siswa" breadcrumbItem="Transaksi Pembayaran Siswa" />
                    <div>
                        <Row>
                            <Col md="10" className="mb-3">
                                <button type="button" className="btn btn-soft-danger waves-effect btn-label waves-light" onClick={() => { handleClickBack() }} data-toggle="modal" style={{ marginRight: "10px" }}>
                                    <i className="bx bx-left-arrow-alt label-icon"></i> Kembali
                                </button>
                            </Col>
                            {/* Kolom Kiri */}
                            <Col xl={6}>
                                <Card>
                                    <CardHeader>
                                        <h5 className="card-title mb-0">Transaksi Bulanan</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <DataTableContainer
                                            columns={columns}
                                            data={list}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>

                            {/* Kolom Kanan */}
                            <Col xl={6}>
                                <Card>
                                    <CardHeader className="mb-2">
                                        <h5 className="card-title mb-0">Detail Identitas</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Table>
                                            <tbody>
                                                <tr style={{ height: "47px" }}>
                                                    <td><strong>Jenis Pembayaran</strong></td>
                                                    <td>: {detail.cost_name}</td>
                                                </tr>
                                                <tr style={{ height: "47px" }}>
                                                    <td><strong>Tahun Pelajaran</strong></td>
                                                    <td>: {detail.school_year}</td>
                                                </tr>
                                                <tr style={{ height: "47px" }}>
                                                    <td><strong>Tipe Pembayaran</strong></td>
                                                    <td>: {detail.cost_type}</td>
                                                </tr>
                                                <tr style={{ height: "47px" }}>
                                                    <td><strong>N I M</strong></td>
                                                    <td>: {detail.registration_no}</td>
                                                </tr>
                                                <tr style={{ height: "47px" }}>
                                                    <td><strong>Nama</strong></td>
                                                    <td>: {detail.student_name}</td>
                                                </tr>
                                                <tr style={{ height: "47px" }}>
                                                    <td><strong>Tempat, Tanggal Lahir</strong></td>
                                                    <td>: {detail.ttl}</td>
                                                </tr>
                                                <tr style={{ height: "47px" }}>
                                                    <td><strong>Jenis Kelamin</strong></td>
                                                    <td>: {detail.gender}</td>
                                                </tr>
                                                <tr style={{ height: "47px" }}>
                                                    <td><strong>Kelas</strong></td>
                                                    <td>: {detail.class_name}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Modal
                    isOpen={payment_modal}
                    toggle={() => {
                        handleClickPayment()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Bayar
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("payment")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationPaymentType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <Table>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>N I S</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>{detail.registration_no}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Nama</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>{detail.student_name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Kelas Terakhir</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>{detail.class_name}</td>
                                    </tr>
                                    <tr style={{ height: "50px" }}>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Tanggal Transaksi</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="dd-mm-yyyy"
                                                value={validationPaymentType.values.payment_date}
                                                onChange={([selectedDate]) => {
                                                    const formattedDate = selectedDate
                                                        ? selectedDate.toLocaleDateString("id-ID").replace(/\//g, "-")
                                                        : "";
                                                    validationPaymentType.setFieldValue('payment_date', formattedDate);
                                                }}
                                                options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "d-m-Y"
                                                }}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Cara Bayar</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>
                                            <Input
                                                type="select"
                                                name="payment_method"
                                                id="payment_method"
                                                value={validationPaymentType.values.payment_method || ""}
                                                onChange={validationPaymentType.handleChange}
                                                required
                                            >
                                                {get_method.map((method, index) => (
                                                    <option value={method.name} key={index}>{method.name}</option>
                                                ))}

                                            </Input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Nilai Transaksi</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td style={{ textAlign: "right", paddingRight: "25px" }}>{validationPaymentType.values.transaction_value}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Pembayaran</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td>
                                            {/* Input untuk uang yang dibayarkan */}
                                            <Input
                                                type="text"
                                                name="paid_in_cash"
                                                id="paid_in_cash"
                                                placeholder="Enter paid in cash"
                                                value={validationPaymentType.values.paid_in_cash}
                                                onChange={(e) => handleChangeWithRupiah(e)}
                                                style={{ textAlign: "right" }}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}><strong>Uang Kembalian</strong></td>
                                        <td style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>:</td>
                                        <td style={{ textAlign: "right", paddingRight: "25px" }}>{validationPaymentType.values.change_money}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" style={{ verticalAlign: "middle", textAlign: "left", paddingRight: "10px" }}>
                                            <div className="mb-3">
                                                <Label className="form-label">Deskripsi</Label>
                                                <Input
                                                    type="textarea"
                                                    name="description"
                                                    id="description"
                                                    placeholder="Enter deskripsi"
                                                    value={validationPaymentType.values.description}
                                                    onChange={validationPaymentType.handleChange}
                                                    required
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Proses Bayar</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("payment")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
            </div >
        </React.Fragment >
    )
};

export default MonthlyPayments;
