import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_cost_type = (size, page, search, school_year, section) => {
    return axiosApi
        .get(API_URL + `cost_type_student?limit=${size}&page=${page}&search=${search}&school_year=${school_year}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_cost_type = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_cost_type_student", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const add_cost_type = (bodyParam) => {
    return axiosApi
        .post(API_URL + "cost_type_student", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_cost_type = (param) => {
    return axiosApi
        .get(API_URL + "cost_type_student/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_cost_type = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "cost_type_student/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const delete_cost_type = (param) => {
    return axiosApi
        .delete(API_URL + "cost_type_student/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_section = (section) => {
    return axiosApi
        .get(API_URL + `get_section?section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_receive_post = () => {
    return axiosApi
        .get(API_URL + `all_reception_post`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const copy_cost_type = (bodyParam) => {
    return axiosApi
        .post(API_URL + "copy_cost_type_student", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const costType = {
    all_cost_type,
    multi_delete_cost_type,
    add_cost_type,
    view_cost_type,
    edit_cost_type,
    delete_cost_type,
    get_section,
    get_receive_post,
    copy_cost_type,
    API_URL,
};

export default costType;