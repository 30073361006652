import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, Form, Label, Button } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import { ModalConfirm, ModalInfo, ModalConfirmation } from "../../components/Common/ModalCustoms";
import Kas from "../../services/Kas";
import CostType from "../../services/CostTypeService";
import section from "../../services/SectionService"
import costType from "../../services/CostTypeService";

const CostTypeStudent = () => {
    const [list, setList] = useState([])
    const [get_section, setSection] = useState([])
    const [get_receive_post, setReceivePost] = useState([])
    const [view_cost_type, setViewCostType] = useState("");
    const [edit_cost_type, setEditCostType] = useState("");
    const [view_modal, setview_modal] = useState(false)
    const [add_modal, setadd_modal] = useState(false)
    const [schoolYear, setSchoolYear] = useState("");
    const [cpSchoolYear, setCpSchoolYear] = useState("");
    const [edit_modal, setedit_modal] = useState(false)
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        var btnDelete = document.getElementById("btnDelete")
        btnDelete.style.visibility = "hidden";
        const controller = new AbortController()
        const newYearFrom = parseInt(validationSearchType.values.school_year_from);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        const cpSchoolYear = (newYearFrom + 1) + "/" + (newYearFrom + 2);
        setSchoolYear(schoolYear)
        setCpSchoolYear(cpSchoolYear)
        initData(10, 1, searchTerm, schoolYear)
        return () => controller.abort()
    }, [])

    const initData = async (size, page, search, school_year) => {
        try {
            let sections = "";

            const authUser = JSON.parse(localStorage.getItem("authUser"));

            if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
                !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
            ) {
                sections = authUser.sections[0];
            }

            const result = await CostType.all_cost_type(size, page, search, school_year, sections)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                is_include_spp: (
                    <input
                        type="checkbox"
                        checked={item.is_include_spp} // Set checked based on item.is_include_spp
                        readOnly // Optional: Make the checkbox read-only
                    />
                ),
                is_not_arrears: (
                    <input
                        type="checkbox"
                        checked={item.is_not_arrears} // Set checked based on item.is_include_spp
                        readOnly // Optional: Make the checkbox read-only
                    />
                ),
                created_date: Moment(item.created_date).utc().format('yyyy-MM-DD hh:mm:ss'),
                updated_date: Moment(item.updated_date).utc().format('yyyy-MM-DD hh:mm:ss'),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setList(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };


    const checkedBoxesFormatter = (row) => {
        return (
            <div style={{ textAlign: "center" }}>
                <input type="checkbox" name="checkbox" value={row.id} onClick={() => { handleClickCheckboxes(row.id) }} />
            </div>
        )
    };

    const rankFormatterPembayaran = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-primary waves-effect btn-label waves-light" onClick={() => { handleSettingCostStudent(row.type, row.id) }} data-toggle="modal" style={{ marginRight: "10px" }}>
                    <i className="bx bx-purchase-tag label-icon"></i> Atur Tarif
                </button>
            </div>
        )
    };

    const rankFormatter = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(row.id) }} data-toggle="modal" style={{ marginRight: "10px" }}>
                    <i className="bx bx-show font-size-16 align-middle"></i>
                </button>
                <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickEdit(row.id) }} data-toggle="modal" style={{ marginRight: "10px" }}>
                    <i className="bx bx-edit font-size-16 align-middle"></i>
                </button>
                <button type="button" className="btn btn-soft-danger waves-effect waves-light" onClick={() => { handleClickDeleteOne(row.id) }}>
                    <i className="bx bx-trash font-size-16 align-middle"></i>
                </button>
            </div>
        )
    };

    function handleClickCheckboxes() {
        let checkedBoxes = document.querySelectorAll(`input[type=checkbox][name="checkbox"]:checked`)
        var btnDelete = document.getElementById("btnDelete")
        btnDelete.style.visibility = checkedBoxes.length > 1 ? 'visible' : 'hidden';
    }

    const columns = [
        {
            name: '#',
            selector: row => row.checkbox,
            sortable: false,
            cell: checkedBoxesFormatter,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '5%',
        },
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '7%',
        },
        {
            name: 'Pos Penerimaan',
            selector: row => row.post.name,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Nama Biaya',
            selector: row => row.cost_name,
            sortable: true,
            width: '14%',
        },
        {
            name: 'Tipe Biaya',
            selector: row => row.type,
            sortable: true,
            width: '10%',
        },
        {
            name: (
                <div style={{ whiteSpace: 'normal', textAlign: 'center' }}>
                    Bagian Dari<br />Total Kartu SPP
                </div>
            ),
            selector: row => row.is_include_spp,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '10%',
        },
        {
            name: (
                <div style={{ whiteSpace: 'normal', textAlign: 'center' }}>
                    Tidak Dianggap<br />Menunggak
                </div>
            ),
            selector: row => row.is_not_arrears,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '10%',
        },
        {
            name: "Tarif Pembayaran",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatterPembayaran,
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm, schoolYear)
    };

    const handleSchoolYear = (event) => {
        const newYearFrom = parseInt(event.target.value);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYear(schoolYear)
        validationSearchType.setFieldValue('school_year', schoolYear);
        initData(currentSize, currentPage, searchTerm, schoolYear)
        validationSearchType.setFieldValue('school_year_from', newYearFrom);
        validationSearchType.setFieldValue('school_year_to', parseInt(newYearFrom) + 1);

        const cpSchoolYear = (newYearFrom + 1) + "/" + (newYearFrom + 2);
        setCpSchoolYear(cpSchoolYear)
        validationSearchType.setFieldValue('cp_school_year_from', parseInt(newYearFrom + 1));
        validationSearchType.setFieldValue('cp_school_year_to', parseInt(newYearFrom) + 2);
    };

    const handleCpSchoolYear = (event) => {
        const newYearFrom = parseInt(event.target.value);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setCpSchoolYear(schoolYear)
        validationSearchType.setFieldValue('school_year', schoolYear);
        validationSearchType.setFieldValue('cp_school_year_from', newYearFrom);
        validationSearchType.setFieldValue('cp_school_year_to', parseInt(newYearFrom) + 1);
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm, schoolYear)
        document.getElementById("btnDelete").style.visibility = "hidden";
    };

    const handleClickCopySchoolYear = async () => {
        ModalConfirmation(
            '',
            `Anda yakin ingin mencopy jenis biaya yang ada pada tahun pelajaran ${schoolYear} ke tahun ${cpSchoolYear}?`,
            'warning',
            'Copy Daftar Jenis Biaya Siswa',
            () => processCopyCostType({ "school_year_from": schoolYear, "school_year_to": cpSchoolYear }),
        )
    }

    const processCopyCostType = async (values) => {
        try {
            await CostType.copy_cost_type(values);
            ModalInfo(
                "Berhasil!",
                "Copy data sudah berhasil dilakukan.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm, schoolYear)
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                ModalInfo(
                    "Upss!",
                    error.response?.data?.message,
                    "warning",
                    false
                )
            }
        }
    }

    const handleClickAdd = () => {
        setadd_modal(!add_modal)
        validationAddType.resetForm()
        const newYearFrom = parseInt(validationSearchType.values.school_year_from);
        const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        setSchoolYear(schoolYear)
        getSection()
        getReceivePost()
    };

    const handleSettingCostStudent = (type, id) => {
        if (type == "BULANAN") {
            window.location.replace('/panel/payment/list-cost-type-student/monthly-cost-student/' + id)
        } else {
            window.location.replace('/panel/payment/list-cost-type-student/non-monthly-cost-student/' + id)
        }
    };

    function getReceivePost() {
        costType.get_receive_post().then(
            (response) => {
                setReceivePost(response.data)
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function getSection() {
        let sections = "";

        const authUser = JSON.parse(localStorage.getItem("authUser"));

        if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
            !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
        ) {
            sections = authUser.sections[0];
        }

        section.get_section(sections).then(
            (response) => {
                if (response.data.length === 1) {
                    validationAddType.setFieldValue("section_id", response.data[0].id)
                    validationEditType.setFieldValue("section_id", response.data[0].id)
                    setSection(response.data)
                } else {
                    setSection(response.data)
                }
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "edit") {
            setedit_modal(false)
        } else {
            setadd_modal(false)
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickView = async (id) => {
        try {
            const data = await CostType.view_cost_type(id)
            const updatedData = { ...data };
            updatedData.post_name = updatedData.post.name;
            setViewCostType(updatedData)
            setview_modal(!view_modal)
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickEdit = (id) => {
        CostType.view_cost_type(id).then(
            (data) => {
                setedit_modal(!edit_modal)
                setEditCostType(data)
                getSection()
                getReceivePost()
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    };

    const handleClickDeleteSelected = () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox][name="checkbox"]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        } else {
            ModalConfirm(
                "Are you sure?",
                "You won't be able to revert this!",
                "warning",
                "list cost type settings",
                () => processClickDeleteSelected(),
            )
        }
    };

    const processClickDeleteSelected = async () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox][name="checkbox"]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))

        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        }

        try {
            await CostType.multi_delete_cost_type({ id: arrayCheck })
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm, schoolYear)
            document.getElementById("btnDelete").style.visibility = "hidden"
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                ModalInfo(
                    "Error",
                    "An unexpected error occurred.",
                    "error",
                    false
                )
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickDeleteOne = (id) => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "list cost type settings",
            () => processClickDeleteOne(id),
        )
    };

    const processClickDeleteOne = async (id) => {
        try {
            await CostType.delete_cost_type(id)
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm, schoolYear)
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value, schoolYear)
        setFiltered(list)
    };

    document.title = `Daftar Jenis Biaya Siswa | ${webConfig[0].name} - ${webConfig[0].title}`;

    const current_year = new Date().getFullYear();
    const validationSearchType = useFormik({
        enableReinitialize: true,
        initialValues: {
            school_year_from: current_year,
            school_year_to: current_year + 1,
            cp_school_year_from: current_year + 1,
            cp_school_year_to: current_year + 2,
            section_id: "",
        },
        onSubmit: async (values, { resetForm }) => { }
    })

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            post_id: "",
            cost_name: "",
            type: "NON BULANAN",
            is_include_spp: false,
            is_not_arrears: false,
            school_year: schoolYear,
            section_id: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await costType.add_cost_type(values)
                handleClickClose("add")
                initData(currentSize, currentPage, searchTerm, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    ModalInfo(
                        "Upss!",
                        error.response.data.message,
                        "warning",
                        false
                    )
                }
            }
        }
    })

    const validationEditType = useFormik({
        enableReinitialize: true,
        initialValues: {
            post_id: edit_cost_type.post_id,
            cost_name: edit_cost_type.cost_name,
            type: edit_cost_type.type,
            is_include_spp: edit_cost_type.is_include_spp,
            is_not_arrears: edit_cost_type.is_not_arrears,
            section_id: edit_cost_type.section_id
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await CostType.edit_cost_type(edit_cost_type.id, values)
                handleClickClose("edit")
                initData(currentSize, currentPage, searchTerm, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const validationStudentCostType = useFormik({
        enableReinitialize: true,
        initialValues: {
            post_id: "",
            cost_name: "",
            type: "",
            is_include_spp: false,
            is_not_arrears: false,
            section_id: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await costType.add_cost_type(values)
                handleClickClose("add")
                initData(currentSize, currentPage, searchTerm, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    ModalInfo(
                        "Upss!",
                        error.response.data.message,
                        "warning",
                        false
                    )
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Keuangan" breadcrumbItem="Daftar Jenis Biaya Siswa" />
                    <Row className="mb-3">
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <div style={{ flex: '0 0 130px' }}>
                                <Label className="form-label">
                                    <span style={{ color: 'red' }}>*</span> Tahun Pelajaran :
                                </Label>
                            </div>
                            <div className="mb-2" style={{ flex: '0 0 85px' }}>
                                <Input
                                    type="number"
                                    name="school_year_from"
                                    id="school_year_from"
                                    value={validationSearchType.values.school_year_from}
                                    onChange={handleSchoolYear}
                                    style={{ width: '85px', height: '35px' }}
                                    required
                                />
                            </div>
                            <div className="mb-2" style={{ flex: '0 0 100px' }}>
                                <Input
                                    type="number"
                                    name="school_year_to"
                                    id="school_year_to"
                                    value={validationSearchType.values.school_year_to}
                                    onChange={validationSearchType.handleChange}
                                    style={{ width: '85px', height: '35px' }}
                                    disabled
                                    required
                                />
                            </div>
                            <div className="mb-2" style={{ flex: '0 0 200px' }}>
                                <button type="button" className="btn btn-soft-dark waves-effect btn-label waves-light" onClick={() => { handleClickCopySchoolYear() }} data-toggle="modal" style={{ marginRight: "10px" }}>
                                    <i className="bx bx-arrow-from-left label-icon"></i> Copy Yang Dipilih Ke
                                </button>
                            </div>
                            <div className="mb-2" style={{ flex: '0 0 85px' }}>
                                <Input
                                    type="number"
                                    name="cp_school_year_from"
                                    id="cp_school_year_from"
                                    value={validationSearchType.values.cp_school_year_from}
                                    onChange={handleCpSchoolYear}
                                    style={{ width: '85px', height: '35px' }}
                                    required
                                />
                            </div>
                            <div className="mb-2" style={{ flex: '0 0 100px' }}>
                                <Input
                                    type="number"
                                    name="cp_school_year_to"
                                    id="cp_school_year_to"
                                    value={validationSearchType.values.cp_school_year_to}
                                    onChange={validationSearchType.handleChange}
                                    style={{ width: '85px', height: '35px' }}
                                    disabled
                                    required
                                />
                            </div>
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <ButtonContainer
                            isButtonAdd={true}
                            textButtonAdd="Tambah"
                            isButtonDelete={true}
                            handleClickAdd={handleClickAdd}
                            handleClickDeleteSelected={handleClickDeleteSelected}
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={add_modal}
                    toggle={() => {
                        handleClickAdd()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Add
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("add")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Pos Penerimaan</Label>
                                <Input
                                    type="select"
                                    name="post_id"
                                    id="post_id"
                                    value={validationAddType.values.post_id}
                                    onChange={validationAddType.handleChange}
                                    required
                                >
                                    <option value=""></option>
                                    {get_receive_post.map((post, index) => (
                                        <option value={post.id} key={index}>{post.name}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Nama Biaya</Label>
                                <Input
                                    type="text"
                                    name="cost_name"
                                    id="cost_name"
                                    placeholder="Enter nama biaya"
                                    value={validationAddType.values.cost_name}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Tipe Biaya</Label>
                                <Input
                                    type="select"
                                    name="type"
                                    id="type"
                                    value={validationAddType.values.type}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        validationAddType.setFieldValue("type", selectedValue);
                                        if (selectedValue === "NON BULANAN") {
                                            validationAddType.setFieldValue("is_include_spp", false);
                                        }
                                    }}
                                    required
                                >
                                    <option value="NON BULANAN">NON BULANAN</option>
                                    <option value="BULANAN">BULANAN</option>
                                </Input>
                            </div>
                            <div className="mb-3">
                                <div className="d-flex align-items-center">
                                    <Input
                                        type="checkbox"
                                        name="is_include_spp"
                                        id="is_include_spp"
                                        checked={validationAddType.values.is_include_spp || false}
                                        disabled={!validationAddType.values.type || validationAddType.values.type === "NON BULANAN"}
                                        onChange={(e) =>
                                            validationAddType.setFieldValue(
                                                "is_include_spp",
                                                e.target.checked
                                            )
                                        }
                                        style={{ marginRight: "10px" }}
                                    />
                                    <span className="mb-0" style={{
                                        color: !validationAddType.values.type || validationAddType.values.type === "NON BULANAN"
                                            ? "#888888"
                                            : "#262626",
                                    }}>
                                        Nominal pembayaran biaya ini adalah bagian dari total nominal yang tertera di kartu SPP
                                    </span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span style={{ fontSize: "12px", paddingLeft: "25px", color: "#AB36FF" }}>
                                        Centang opsi ini jika transaksi pembayaran biaya ini ingin masuk pada kartu SPP
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="d-flex align-items-center">
                                    <Input
                                        type="checkbox"
                                        name="is_not_arrears"
                                        id="is_not_arrears"
                                        checked={validationAddType.values.is_not_arrears}
                                        style={{ marginRight: "10px" }}
                                    />
                                    <span className="mb-0">
                                        Biaya ini jangan dianggap menunggak jika belum membayar
                                    </span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span style={{ fontSize: "12px", paddingLeft: "25px", color: "#AB36FF" }}>
                                        Jika opsi ini dicentang, maka biaya ini tidak akan masuk dalam laporan tunggakan atau tidak akan dianggap menunggak walau belum membayar.
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3" hidden={
                                localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                !(
                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                )
                            }>
                                <Label className="form-label">Bagian</Label>
                                <Input
                                    type="select"
                                    name="section_id"
                                    id="section_id"
                                    value={validationAddType.values.section_id}
                                    onChange={validationAddType.handleChange}
                                    disabled={
                                        localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                    }
                                    style={{
                                        backgroundColor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'lightgray'
                                                : 'white', // Set the color based on whether the input is disabled
                                        cursor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'not-allowed'
                                                : 'default', // Set the cursor style based on whether the input is disabled
                                    }}
                                    required
                                >
                                    {localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                        ? (
                                            <div></div>
                                        ) : (
                                            <option value="">Select section</option>
                                        )
                                    }
                                    {get_section.map((section, index) => (
                                        <option value={section.id} key={index}>{section.section_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("add")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={edit_modal}
                    toggle={() => {
                        handleClickEdit()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Edit
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("edit")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationEditType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Pos Penerimaan</Label>
                                <Input
                                    type="select"
                                    name="post_id"
                                    id="post_id"
                                    value={validationEditType.values.post_id}
                                    onChange={validationEditType.handleChange}
                                    required
                                >
                                    <option value=""></option>
                                    {get_receive_post.map((post, index) => (
                                        <option value={post.id} key={index}>{post.name}</option>
                                    ))}
                                </Input>
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Nama Biaya</Label>
                                <Input
                                    type="text"
                                    name="cost_name"
                                    id="cost_name"
                                    placeholder="Enter nama biaya"
                                    value={validationEditType.values.cost_name}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Tipe Biaya</Label>
                                <Input
                                    type="select"
                                    name="type"
                                    id="type"
                                    value={validationEditType.values.type}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        validationEditType.setFieldValue("type", selectedValue);
                                        if (selectedValue === "NON BULANAN") {
                                            validationEditType.setFieldValue("is_include_spp", false);
                                        }
                                    }}
                                    required
                                >
                                    <option value="NON BULANAN">NON BULANAN</option>
                                    <option value="BULANAN">BULANAN</option>
                                </Input>
                            </div>
                            <div className="mb-3">
                                <div className="d-flex align-items-center">
                                    <Input
                                        type="checkbox"
                                        name="is_include_spp"
                                        id="is_include_spp"
                                        checked={validationEditType.values.is_include_spp || false}
                                        disabled={!validationEditType.values.type || validationEditType.values.type === "NON BULANAN"}
                                        onChange={(e) =>
                                            validationEditType.setFieldValue(
                                                "is_include_spp",
                                                e.target.checked
                                            )
                                        }
                                        style={{ marginRight: "10px" }}
                                    />
                                    <span className="mb-0" style={{
                                        color: !validationEditType.values.type || validationEditType.values.type === "NON BULANAN"
                                            ? "#888888"
                                            : "#262626",
                                    }}>
                                        Nominal pembayaran biaya ini adalah bagian dari total nominal yang tertera di kartu SPP
                                    </span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span style={{ fontSize: "12px", paddingLeft: "25px", color: "#AB36FF" }}>
                                        Centang opsi ini jika transaksi pembayaran biaya ini ingin masuk pada kartu SPP
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="d-flex align-items-center">
                                    <Input
                                        type="checkbox"
                                        name="is_not_arrears"
                                        id="is_not_arrears"
                                        checked={validationEditType.values.is_not_arrears || false}
                                        onChange={(e) =>
                                            validationEditType.setFieldValue(
                                                "is_not_arrears",
                                                e.target.checked
                                            )
                                        }
                                        style={{ marginRight: "10px" }}
                                    />
                                    <span className="mb-0">
                                        Biaya ini jangan dianggap menunggak jika belum membayar
                                    </span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span style={{ fontSize: "12px", paddingLeft: "25px", color: "#AB36FF" }}>
                                        Jika opsi ini dicentang, maka biaya ini tidak akan masuk dalam laporan tunggakan atau tidak akan dianggap menunggak walau belum membayar.
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3" hidden={
                                localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                !(
                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                )
                            }>
                                <Label className="form-label">Bagian</Label>
                                <Input
                                    type="select"
                                    name="section_id"
                                    id="section_id"
                                    value={validationEditType.values.section_id}
                                    onChange={validationEditType.handleChange}
                                    disabled={
                                        localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                    }
                                    style={{
                                        backgroundColor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'lightgray'
                                                : 'white', // Set the color based on whether the input is disabled
                                        cursor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'not-allowed'
                                                : 'default', // Set the cursor style based on whether the input is disabled
                                    }}
                                    required
                                >
                                    {localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                        ? (
                                            <div></div>
                                        ) : (
                                            <option value="">Select section</option>
                                        )
                                    }
                                    {get_section.map((section, index) => (
                                        <option value={section.id} key={index}>{section.section_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("edit")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal >
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="4">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_cost_type.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Pos Penerimaan
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_cost_type.post_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Nama Biaya
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_cost_type.cost_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Tipe Biaya
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_cost_type.type}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Bagian Dari Total Kartu SPP
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                <input
                                    type="checkbox"
                                    checked={view_cost_type.is_include_spp}
                                    readOnly
                                />
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Tidak Dianggap Menunggak
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                <input
                                    type="checkbox"
                                    checked={view_cost_type.is_not_arrears}
                                    readOnly
                                />
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Created Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_cost_type.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Updated Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_cost_type.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div >
        </React.Fragment >
    )
};

export default CostTypeStudent;
