import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, DataTableContainer, Modal, Form, Label, Input } from "reactstrap";
import school from "../../assets/images/ic-school.png";
import users from "../../assets/images/ic-list-users.png";
import change from "../../assets/images/ic-change-password.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import section from "../../services/SectionService"
import system from "../../services/SystemService"
import { ModalInfo } from "../../components/Common/ModalCustoms"
import { useFormik } from "formik";

const System = () => {
    const [selected, setSelected] = useState(null);
    const [get_section, setSection] = useState([])
    const [school_data_modal, setschool_data_modal] = useState(false)
    const [change_password_modal, setchange_password_modal] = useState(false)
    const [passwordError, setPasswordError] = useState('');
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    //meta title
    document.title = `Sistem | ${webConfig[0].name} - ${webConfig[0].title}`;

    const authUser = JSON.parse(localStorage.getItem("authUser"));

    function handleClickClose(action) {
        if (action === "school_data") {
            setSelected(selected === "school_data" ? null : "school_data");
            setschool_data_modal(false)
        } else if (action === "change_password") {
            setSelected(selected === "change_password" ? null : "change_password");
            setchange_password_modal(false)
        }
    }

    function getSection() {
        let sections = "";

        const authUser = JSON.parse(localStorage.getItem("authUser"));

        if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
            !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
        ) {
            sections = authUser.sections[0];
        }

        section.get_section(sections).then(
            (response) => {
                if (response.data.length === 1) {
                    validationSchoolDataType.setFieldValue("section_id", response.data[0].id)
                    setSection(response.data)
                    getSchoolData(response.data[0].id)
                } else {
                    setSection(response.data)
                    getSchoolData("")
                }
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function getUsersDetail() {
        section.get_section(sections).then(
            (response) => {
                if (response.data.length === 1) {
                    validationSchoolDataType.setFieldValue("section_id", response.data[0].id)
                    setSection(response.data)
                    getSchoolData(response.data[0].id)
                } else {
                    setSection(response.data)
                    getSchoolData("")
                }
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function getSchoolData(section_id) {
        system.get_school_data(section_id).then(
            (response) => {
                if (response.data !== null) {
                    validationSchoolDataType.setFieldValue("id", response.data.id)
                    validationSchoolDataType.setFieldValue("school_name", response.data.school_name)
                    validationSchoolDataType.setFieldValue("school_address", response.data.school_address)
                    validationSchoolDataType.setFieldValue("school_phone", response.data.school_phone)
                    validationSchoolDataType.setFieldValue("addition", response.data.addition)
                } else {
                    validationSchoolDataType.setFieldValue("id", "")
                    validationSchoolDataType.setFieldValue("school_name", "")
                    validationSchoolDataType.setFieldValue("school_address", "")
                    validationSchoolDataType.setFieldValue("school_phone", "")
                    validationSchoolDataType.setFieldValue("addition", "")
                }
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    const handleClickSchoolData = () => {
        validationSchoolDataType.resetForm()
        setSelected(selected === "school_data" ? null : "school_data");
        setschool_data_modal(!school_data_modal)
        getSection()
    }

    const handleClickChangePassword = () => {
        validationChangePasswordType.resetForm()
        setSelected(selected === "change_password" ? null : "change_password");
        setchange_password_modal(!change_password_modal)
    }

    const handleChangeSection = (event) => {
        const section_id = event.target.value;
        validationSchoolDataType.setFieldValue("section_id", section_id)
        getSchoolData(section_id)
    };

    const validationSchoolDataType = useFormik({
        enableReinitialize: true,
        initialValues: {
            school_name: "",
            school_address: "",
            school_phone: "",
            addition: "",
            section_id: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await system.update_school_data(values.id, values)
                handleClickClose("school_data")
                ModalInfo(
                    "Berhasil",
                    "Data sekolah berhasil diubah.",
                    "success",
                    false
                )
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }

                ModalInfo(
                    "Gagal",
                    "Data sekolah gagal diubah.",
                    "danger",
                    false
                )
            }
        }
    })

    const validationChangePasswordType = useFormik({
        enableReinitialize: true,
        initialValues: {
            full_name: authUser.full_name,
            username: authUser.username,
            email: authUser.email,
            old_password: "",
            new_password: "",
            re_password: ""
        },
        onSubmit: async (values, { resetForm }) => {

            if (values.new_password !== values.re_password) {
                setPasswordError("Opss! Password baru tidak sama")
            }

            try {
                setSelected(selected === "change_password" ? null : "change_password");
                await system.update_change_password(values.username, values)
                handleClickClose("change_password")
                ModalInfo(
                    "Berhasil",
                    "Password berhasil diubah.",
                    "success",
                    false
                )
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }

                ModalInfo(
                    "Gagal",
                    error.response?.data?.message,
                    "error",
                    false
                )
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Sistem" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name="btnradio"
                                        id="btnradio1"
                                        autoComplete="off"
                                        checked={selected === "school_data"}
                                        onClick={() => { handleClickSchoolData() }}
                                    />
                                    <label
                                        className="btn btn-outline-light"
                                        htmlFor="btnradio1"
                                        style={{ width: "200px" }}
                                    >
                                        <img src={school} alt="School" style={{ maxWidth: "20%" }} /><br />
                                        Data Sekolah<br />
                                    </label>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name="btnradio"
                                        id="btnradio2"
                                        autoComplete="off"
                                    />
                                    <label
                                        className="btn btn-outline-light"
                                        htmlFor="btnradio2"
                                        style={{ width: "200px" }}
                                    >
                                        <img src={users} alt="Users" style={{ maxWidth: "20%" }} /><br />
                                        Daftar Pengguna Aplikasi
                                    </label>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name="btnradio"
                                        id="btnradio3"
                                        autoComplete="off"
                                        checked={selected === "change_password"}
                                        onClick={() => { handleClickChangePassword() }}
                                    />
                                    <label
                                        className="btn btn-outline-light"
                                        htmlFor="btnradio3"
                                        style={{ width: "200px" }}
                                    >
                                        <img src={change} alt="School" style={{ maxWidth: "20%" }} /><br />
                                        Ubah Password
                                    </label>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    isOpen={school_data_modal}
                    toggle={() => {
                        handleClickSchoolData()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Data Sekolah
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("school_data")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationSchoolDataType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Nama Sekolah</Label>
                                <Input
                                    type="text"
                                    name="school_name"
                                    id="school_name"
                                    placeholder="Masukan nama sekolah"
                                    value={validationSchoolDataType.values.school_name}
                                    onChange={validationSchoolDataType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Alamat</Label>
                                <Input
                                    type="textarea"
                                    name="school_address"
                                    id="school_address"
                                    placeholder="Masukan alamat sekolah"
                                    value={validationSchoolDataType.values.school_address}
                                    onChange={validationSchoolDataType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Telepon/Wa</Label>
                                <Input
                                    type="text"
                                    name="school_phone"
                                    id="school_phone"
                                    placeholder="Masukan telepon/wa"
                                    value={validationSchoolDataType.values.school_phone}
                                    onChange={validationSchoolDataType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Kop Tambahan</Label>
                                <Input
                                    type="text"
                                    name="addition"
                                    id="addition"
                                    placeholder="Masukan telepon"
                                    value={validationSchoolDataType.values.addition}
                                    onChange={validationSchoolDataType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3" hidden={
                                localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                !(
                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                )
                            }>
                                <Label className="form-label">Bagian</Label>
                                <Input
                                    type="select"
                                    name="section_id"
                                    id="section_id"
                                    value={validationSchoolDataType.values.section_id}
                                    onChange={(e) => handleChangeSection(e)}
                                    disabled={
                                        localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                    }
                                    style={{
                                        backgroundColor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'lightgray'
                                                : 'white',
                                        cursor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'not-allowed'
                                                : 'default',
                                    }}
                                    required
                                >
                                    {localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                        ? (
                                            <div></div>
                                        ) : (
                                            <option value="">Select section</option>
                                        )
                                    }
                                    {get_section.map((section, index) => (
                                        <option value={section.id} key={index}>{section.section_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Update</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("school_data")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>

                <Modal
                    isOpen={change_password_modal}
                    toggle={() => {
                        handleClickChangePassword()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Ubah Password
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("change_password")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationChangePasswordType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Nama Lengkap</Label>
                                <Input
                                    type="text"
                                    name="full_name"
                                    id="full_name"
                                    placeholder="Masukan full_name"
                                    value={validationChangePasswordType.values.full_name}
                                    onChange={validationChangePasswordType.handleChange}
                                    style={{ backgroundColor: 'lightgray' }}
                                    required
                                    disabled
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Nama Pengguna</Label>
                                <Input
                                    type="text"
                                    name="username"
                                    id="username"
                                    placeholder="Masukan username"
                                    value={validationChangePasswordType.values.username}
                                    onChange={validationChangePasswordType.handleChange}
                                    style={{ backgroundColor: 'lightgray' }}
                                    required
                                    disabled
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Masukan email"
                                    value={validationChangePasswordType.values.email}
                                    onChange={validationChangePasswordType.handleChange}
                                    style={{ backgroundColor: 'lightgray' }}
                                    required
                                    disabled
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Masukkan Password Lama</Label>
                                <Input
                                    type="password"
                                    name="old_password"
                                    id="old_password"
                                    placeholder="Masukkan password baru"
                                    value={validationChangePasswordType.values.old_password || ''}
                                    onChange={validationChangePasswordType.handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Masukkan Password Baru</Label>
                                <Input
                                    type="password"
                                    name="new_password"
                                    id="new_password"
                                    placeholder="Masukkan password baru"
                                    value={validationChangePasswordType.values.new_password || ''}
                                    onChange={validationChangePasswordType.handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Masukkan Lagi Password Baru</Label>
                                <Input
                                    type="password"
                                    name="re_password"
                                    id="password1"
                                    placeholder="Masukkan lagi password baru"
                                    value={validationChangePasswordType.values.re_password || ''}
                                    onChange={validationChangePasswordType.handleChange}
                                />
                                {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Update</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("change_password")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
            </div>
        </React.Fragment >
    );
};

export default System;
