import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const get_search_student = (search, school_year, section) => {
    return axiosApi
        .get(API_URL + `get_search_student?search=${search}&school_year=${school_year}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_detail_student = (reg_id, school_year, section) => {
    return axiosApi
        .get(API_URL + `get_detail_student?school_year=${school_year}&registration_no=${reg_id}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const list_cost_monthly = (school_year, reg_id, section) => {
    return axiosApi
        .get(API_URL + `list_cost_monthly?school_year=${school_year}&registration_no=${reg_id}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const list_cost_non_monthly = (school_year, reg_id, section) => {
    return axiosApi
        .get(API_URL + `list_cost_non_monthly?school_year=${school_year}&registration_no=${reg_id}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const all_cost_monthly_detail = (student_id, school_year, section) => {
    return axiosApi
        .get(API_URL + `list_cost_monthly_detail?student_id=${student_id}&school_year=${school_year}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const detail_cost_student = (student_id, school_year, section) => {
    return axiosApi
        .get(API_URL + `detail_cost_student?student_id=${student_id}&school_year=${school_year}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const list_payment_method = (section) => {
    return axiosApi
        .get(API_URL + `list_payment_method?&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const process_payment = (bodyParam) => {
    return axiosApi
        .post(API_URL + 'process_payment', bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const process_payment_monthly = (bodyParam) => {
    return axiosApi
        .post(API_URL + 'process_payment_monthly', bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const process_payment_non_monthly = (bodyParam) => {
    return axiosApi
        .post(API_URL + 'process_payment_non_monthly', bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const list_history_payment = (student_id, section) => {
    return axiosApi
        .get(API_URL + `list_history_payment?student_id=${student_id}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const list_cost_temp = (student_id, section) => {
    return axiosApi
        .get(API_URL + `list_cost_temp?student_id=${student_id}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const detail_list_history_peyment = (history_id, section) => {
    return axiosApi
        .get(API_URL + `detail_list_history_payment?history_id=${history_id}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const delete_cost_temp = (registration_no, section) => {
    return axiosApi
        .delete(API_URL + `delete_cost_temp?registration_no=${registration_no}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const detail_payment = (registration_no, school_year, section) => {
    return axiosApi
        .get(API_URL + `detail_payment?registration_no=${registration_no}&school_year=${school_year}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const delete_payment = (bodyParam) => {
    return axiosApi
        .post(API_URL + `delete_payment`, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};


const student = {
    get_search_student,
    get_detail_student,
    list_cost_monthly,
    list_cost_non_monthly,
    all_cost_monthly_detail,
    detail_cost_student,
    list_payment_method,
    process_payment,
    process_payment_monthly,
    process_payment_non_monthly,
    list_history_payment,
    list_cost_temp,
    detail_list_history_peyment,
    delete_cost_temp,
    detail_payment,
    delete_payment,
    API_URL,
};

export default student;