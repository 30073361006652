import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Input, Row, Col, Modal, Form, Label, Button } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import { ModalConfirm, ModalInfo } from "../../components/Common/ModalCustoms";
import CostType from "../../services/CostTypeService";
import CostNonMonthly from "../../services/CostNonMonthlyService";
import Student from "../../services/StudentService";
import section from "../../services/SectionService"
import costType from "../../services/CostTypeService";

const SettingCostStudentNonMonthly = () => {
    const [list, setList] = useState([])
    const [list_class, setListClass] = useState([])
    const [get_section, setSection] = useState([])
    const [get_receive_post, setReceivePost] = useState([])
    const [view_cost_monthly, setViewCostMonthly] = useState("");
    const [edit_cost_monthly, setEditCostMonthly] = useState("");
    const [view_modal, setview_modal] = useState(false)
    const [add_by_class_modal, setadd_by_class_modal] = useState(false)
    const [add_by_student_modal, setadd_by_student_modal] = useState(false)
    const [schoolYear, setSchoolYear] = useState("");
    const [costTypeData, setCostTypeData] = useState("");
    const [classId, setClassId] = useState('')
    const [student_data, setStudentData] = useState([])
    const [student_cost_modal, setstudent_cost_modal] = useState(false)
    const [edit_modal, setedit_modal] = useState(false)
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))
    const { cost_type } = useParams();
    const [values, setValues] = useState({
        all_month: "",
        months: {
            juli: "",
            agustus: "",
            september: "",
            oktober: "",
            november: "",
            desember: "",
            januari: "",
            februari: "",
            maret: "",
            april: "",
            mei: "",
            juni: "",
        },
    });

    const months = [
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
    ];

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        validationSearchType.setFieldValue('class_id', '');
        const controller = new AbortController()
        initData(10, 1, searchTerm, classId, schoolYear)
        return () => controller.abort()
    }, [])

    const initData = async (size, page, search, classId, school_year) => {
        try {
            let sections = "";

            const authUser = JSON.parse(localStorage.getItem("authUser"));

            if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
                !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
            ) {
                sections = authUser.sections[0];
            }
            const result = await CostNonMonthly.all_cost_non_monthly(size, page, search, classId, school_year, cost_type, sections)
            const formattedData = result.data.map((item, index) => {
                const updatedData = {
                    ...item,
                    no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                    last_class: `[${item.school_year}] ${item.class_name}`,
                    created_date: Moment(item.created_date).utc().format('yyyy-MM-DD hh:mm:ss'),
                    updated_date: Moment(item.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')
                };

                if (item.is_active === 1) {
                    updatedData.status = <span className='badge-soft-info me-1 badge badge-secondary badge-pill'> Calon Siswa </span>;
                } else if (item.is_active === 2) {
                    updatedData.status = <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Aktif </span>;
                } else if (item.is_active === 3) {
                    updatedData.status = <span className='badge-soft-primary me-1 badge badge-secondary badge-pill'> Alumni </span>;
                } else if (item.is_active === 4) {
                    updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Keluar </span>;
                } else if (item.is_active === 5) {
                    updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Dikeluarkan </span>;
                } else if (item.is_active === 6) {
                    updatedData.status = <span className='badge-soft-warning me-1 badge badge-secondary badge-pill'> Pindah Sekolah </span>;
                } else if (item.is_active === 7) {
                    updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Meninggal Dunia </span>;
                }

                return updatedData;
            });

            const Class = await CostNonMonthly.get_class(sections, "", "")
            const formattedDataClass = Class.data.map((item, index) => ({
                ...item
            }))

            const costType = await CostType.view_cost_type(cost_type)
            const updatedData = { ...costType };

            setCostTypeData(updatedData)
            setSchoolYear(updatedData.school_year)
            setListClass(formattedDataClass)
            setStudentData([])
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setList(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const getStatusBadge = (isActive) => {
        const badgeClasses = 'me-1 badge badge-secondary badge-pill';
        switch (isActive) {
            case 1:
                return <span className={`badge-soft-info ${badgeClasses}`}>Calon Siswa</span>;
            case 2:
                return <span className={`badge-soft-success ${badgeClasses}`}>Aktif</span>;
            case 3:
                return <span className={`badge-soft-primary ${badgeClasses}`}>Alumni</span>;
            case 4:
                return <span className={`badge-soft-dark ${badgeClasses}`}>Keluar</span>;
            case 5:
                return <span className={`badge-soft-dark ${badgeClasses}`}>Dikeluarkan</span>;
            case 6:
                return <span className={`badge-soft-warning ${badgeClasses}`}>Pindah Sekolah</span>;
            case 7:
                return <span className={`badge-soft-dark ${badgeClasses}`}>Meninggal Dunia</span>;
            default:
                return <span className={`badge-soft-secondary ${badgeClasses}`}>Tidak Diketahui</span>;
        }
    };

    const rankFormatter = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickEdit(row.id, row.school_year) }} data-toggle="modal" style={{ marginRight: "10px" }}>
                    <i className="bx bx-edit font-size-16 align-middle"></i>
                </button>
                <button type="button" className="btn btn-soft-danger waves-effect waves-light" onClick={() => { handleClickDeleteOne(row.id, row.school_year) }}>
                    <i className="bx bx-trash font-size-16 align-middle"></i>
                </button>
            </div>
        )
    };

    const columns = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '7%',
        },
        {
            name: 'Nomor Induk',
            selector: row => row.registration_no,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Nama Siswa',
            selector: row => row.student_name,
            sortable: true,
            width: '25%',
        },
        {
            name: 'Kelas Terakhir',
            selector: row => row.last_class,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Status Terakhir',
            selector: row => row.status,
            sortable: true,
            width: '15%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
        document.getElementById("btnDelete").style.visibility = "hidden";
    };

    const handleClickAddByClass = () => {
        setadd_by_class_modal(!add_by_class_modal)
        validationAddByClassType.resetForm()
        getSection()
        getReceivePost()
    };

    const handleClickAddByStudent = () => {
        setadd_by_student_modal(!add_by_student_modal)
        validationAddByStudentType.resetForm()
        getSection()
        getReceivePost()
    };

    const handleSettingCostStudent = (id) => {
        window.location.replace('/panel/payment/list-cost-type-student/setting-cost-student/' + id)
    };

    const handleClickBack = () => {
        window.location.replace('../')
    };

    function getReceivePost() {
        costType.get_receive_post().then(
            (response) => {
                setReceivePost(response.data)
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function getSection() {
        let sections = "";

        const authUser = JSON.parse(localStorage.getItem("authUser"));

        if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
            !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
        ) {
            sections = authUser.sections[0];
        }

        section.get_section(sections).then(
            (response) => {
                if (response.data.length === 1) {
                    validationAddType.setFieldValue("section_id", response.data[0].id)
                    validationEditType.setFieldValue("section_id", response.data[0].id)
                    setSection(response.data)
                } else {
                    setSection(response.data)
                }
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "edit") {
            setedit_modal(false)
        } else if (action === "add_by_student") {
            setadd_by_student_modal(false)
        } else if (action === "add_by_class") {
            setadd_by_class_modal(false)
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickEdit = (id, school_year) => {
        CostNonMonthly.view_cost_non_monthly(id, school_year).then(
            (data) => {
                setedit_modal(!edit_modal)
                validationEditType.resetForm()
                setEditCostMonthly(data)
                getSection()
                getReceivePost()
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    };

    const formatNumber = (value) => {
        return new Intl.NumberFormat('id-ID').format(value);
    };

    const handleChangeWithRupiah = (e, from) => {
        const { name, value } = e.target;
        const formattedValue = formatToRupiah(value);
        if (from == "edit") {
            validationEditType.setFieldValue(name, formattedValue);
        } else if (from === "add_by_student") {
            validationAddByStudentType.setFieldValue(name, formattedValue);
        } else if (from === "add_by_class") {
            validationAddByClassType.setFieldValue(name, formattedValue);
        }
    };

    const removeRupiahFormat = (value) => {
        return value.replace(/\D/g, '');
    };

    const formatToRupiah = (value) => {
        const valueString = value ? value.toString() : '';

        const numberString = valueString.replace(/\D/g, '');
        const formatted = new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
        }).format(numberString);

        return formatted.replace("Rp", "").trim();
    };

    const handleAllMonthKeyDown = (e, from) => {
        const { name, value } = e.target;
        if (/[^0-9]/.test(e.key) && e.key !== " " && e.key !== "Backspace") {
            e.preventDefault();
            return;
        }

        if (e.key === " ") {
            if (from === "edit") {
                const numericValue = value.replace(/\D/g, '');

                const months = [
                    "juli", "agustus", "september", "oktober", "november", "desember",
                    "januari", "februari", "maret", "april", "mei", "juni"
                ];
                validationEditType.setFieldValue(name, formatNumber(numericValue));
                months.forEach((month) => {
                    validationEditType.setFieldValue(month, formatNumber(numericValue));
                });
            } else if (from === "add_by_student") {
                const numericValue = value.replace(/\D/g, '');

                const months = [
                    "juli", "agustus", "september", "oktober", "november", "desember",
                    "januari", "februari", "maret", "april", "mei", "juni"
                ];
                validationAddByStudentType.setFieldValue(name, formatNumber(numericValue));
                months.forEach((month) => {
                    validationAddByStudentType.setFieldValue(month, formatNumber(numericValue));
                });
            } else if (from === "add_by_class") {
                const numericValue = value.replace(/\D/g, '');

                const months = [
                    "juli", "agustus", "september", "oktober", "november", "desember",
                    "januari", "februari", "maret", "april", "mei", "juni"
                ];
                validationAddByClassType.setFieldValue(name, formatNumber(numericValue));
                months.forEach((month) => {
                    validationAddByClassType.setFieldValue(month, formatNumber(numericValue));
                });
            }
        }
    };

    const handleChangeClass = async (e, from) => {
        const selectedValue = e.target.value;
        const selectedText = e.target.options[e.target.selectedIndex].text;
        if (from === "search") {
            setClassId(selectedValue)
            validationSearchType.setFieldValue('class_id', selectedValue);
            initData(currentSize, currentPage, searchTerm, selectedValue, schoolYear)
        } else if (from === "add_by_student") {
            let sections = "";
            const authUser = JSON.parse(localStorage.getItem("authUser"));
            if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
                !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
            ) {
                sections = authUser.sections[0];
            }

            if (selectedValue == "") {
                setStudentData([])
            } else {
                const student = await Student.get_student(sections, selectedValue)
                const formattedDataClass = student.data.map((item, index) => ({
                    ...item
                }))
                setStudentData(formattedDataClass)
            }
            validationAddByStudentType.setFieldValue('class_id', selectedValue);
        } else if (from === "add_by_class") {
            setClassId(selectedValue)
            validationAddByClassType.setFieldValue('class_id', selectedValue);
        }
    };

    const handleChangeStudent = async (e, from) => {
        const selectedValue = e.target.value;
        if (from === "add_by_student") {
            validationAddByStudentType.setFieldValue('student_id', selectedValue);
        } else if (from === "add_by_class") {
            validationAddByClassType.setFieldValue('student_id', selectedValue);
        }
    }

    const handleSchoolYear = (event) => {
        // const newYearFrom = parseInt(event.target.value);
        // const schoolYear = newYearFrom + "/" + (newYearFrom + 1);
        // setSchoolYear(schoolYear)
        // validationSearchType.setFieldValue('school_year', schoolYear);
        initData(currentSize, currentPage, searchTerm, classId, schoolYear)
        // validationSearchType.setFieldValue('school_year_from', newYearFrom);
        // validationSearchType.setFieldValue('school_year_to', parseInt(newYearFrom) + 1);
    };

    const handleClickDeleteOne = (id, school_year) => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "list cost monthly settings",
            () => processClickDeleteOne(id, school_year),
        )
    };

    const processClickDeleteOne = async (id, school_year) => {
        try {
            await CostNonMonthly.delete_cost_non_monthly(id, school_year)
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm, classId, schoolYear)
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list)
    };

    document.title = `Atur Tarif Pembayaran Non Bulanan | ${webConfig[0].name} - ${webConfig[0].title}`;

    const [startYear, endYear] = schoolYear.split("/");
    const validationSearchType = useFormik({
        enableReinitialize: true,
        initialValues: {
            school_year_from: startYear,
            school_year_to: endYear,
            section_id: "",
        },
        onSubmit: async (values, { resetForm }) => { }
    })

    const transformData = (values) => {
        const months = [
            "juli", "agustus", "september", "oktober", "november", "desember",
            "januari", "februari", "maret", "april", "mei", "juni"
        ];

        const cost_type_id = cost_type; // Sesuai kebutuhan

        // Ubah data menjadi array yang diinginkan
        return months.map((month) => ({
            student_id: values.student_id,
            month: capitalize(month), // Bulan diubah menjadi huruf besar depan
            rates: removeDots(values[month]), // Hapus format ribuan
            cost_type_id: cost_type_id,
            status: false,
            class_id: values.class_id,
            school_year: costTypeData.school_year,
            section_id: values.section_id
        }));
    };

    // Helper untuk format string: capitalize
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    // Helper untuk menghapus titik pemisah ribuan dari string
    const removeDots = (value) => value.replace(/\./g, "");

    const validationAddByClassType = useFormik({
        enableReinitialize: true,
        initialValues: {
            class_id: "",
            rates: "",
            description: "",
            school_year: schoolYear,
            cost_type_id: cost_type,
            section_id: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                const convertData = {
                    ...values,
                    rates: removeDots(values.rates)
                };
                await CostNonMonthly.add_by_class(convertData)
                handleClickClose("add_by_class")
                initData(currentSize, currentPage, searchTerm, classId, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    ModalInfo(
                        "Upss!",
                        error.response.data.message,
                        "warning",
                        false
                    )
                }
            }
        }
    })

    const validationAddByStudentType = useFormik({
        enableReinitialize: true,
        initialValues: {
            student_id: "",
            rates: "",
            description: "",
            school_year: schoolYear,
            cost_type_id: cost_type,
            section_id: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                const convertData = {
                    ...values,
                    rates: removeDots(values.rates)
                };
                await CostNonMonthly.add_by_student(convertData)
                handleClickClose("add_by_student")
                initData(currentSize, currentPage, searchTerm, classId, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    ModalInfo(
                        "Upss!",
                        error.response.data.message,
                        "warning",
                        false
                    )
                }
            }
        }
    })

    const validationEditType = useFormik({
        enableReinitialize: true,
        initialValues: {
            rates: formatToRupiah(edit_cost_monthly.rates),
            description: edit_cost_monthly.description,
            section_id: formatToRupiah(edit_cost_monthly.section_id),
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                console.log("VALUES : " + JSON.stringify(values))
                const cleanedValues = Object.keys(values).reduce((acc, key) => {
                    if (key === 'rates') {
                        acc[key] = removeRupiahFormat(values[key]);
                    } else {
                        acc[key] = values[key];
                    }
                    return acc;
                }, {});
                await CostNonMonthly.edit_cost_non_monthly(edit_cost_monthly.id, edit_cost_monthly.school_year, cleanedValues)
                handleClickClose("edit")
                initData(currentSize, currentPage, searchTerm, classId, schoolYear)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Keuangan" breadcrumbItem="Atur Tarif Pembayaran Non Bulanan" />
                    <Row className="mb-3">
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <div style={{ flex: '0 0 130px' }}>
                                <Label className="form-label">
                                    <span style={{ color: 'red' }}>*</span> Tahun Pelajaran :
                                </Label>
                            </div>
                            <div className="mb-2" style={{ flex: '0 0 85px' }}>
                                <Input
                                    type="number"
                                    name="school_year_from"
                                    id="school_year_from"
                                    value={validationSearchType.values.school_year_from}
                                    onChange={handleSchoolYear}
                                    style={{ width: '85px', height: '35px' }}
                                    disabled
                                    required
                                />
                            </div>
                            <div className="mb-2" style={{ flex: '0 0 100px' }}>
                                <Input
                                    type="number"
                                    name="school_year_to"
                                    id="school_year_to"
                                    value={validationSearchType.values.school_year_to}
                                    onChange={validationSearchType.handleChange}
                                    style={{ width: '85px', height: '35px' }}
                                    disabled
                                    required
                                />
                            </div>
                            <div style={{ flex: '0 0 70px', textAlign: 'left' }}>
                                <Label className="form-label">
                                    <span style={{ color: 'red' }}>*</span> Kelas :
                                </Label>
                            </div>
                            <div className="mb-2" style={{ flex: '0 0 50px' }}>
                                <Input
                                    type="select"
                                    name="class_id"
                                    id="class_id"
                                    value={validationSearchType.values.class_id || ''}
                                    onChange={(e) => handleChangeClass(e, "search")}
                                    style={{ width: '200px', height: '35px', textAlign: 'left' }}
                                    required
                                >
                                    <option value="">Semua Kelas</option>
                                    {list_class.map((classes, index) => (
                                        <option value={classes.id} key={index}>{classes.class_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <Col md="10">
                            <button type="button" className="btn btn-soft-danger waves-effect btn-label waves-light" onClick={() => { handleClickBack() }} data-toggle="modal" style={{ marginRight: "10px" }}>
                                <i className="bx bx-left-arrow-alt label-icon"></i> Kembali
                            </button>
                            <button type="button" className="btn btn-soft-success waves-effect btn-label waves-light" onClick={() => { handleClickAddByClass("") }} data-toggle="modal" style={{ marginRight: "10px" }}>
                                <i className="bx bx-plus label-icon"></i> Berdasarkan Kelas
                            </button>
                            <button type="button" className="btn btn-soft-primary waves-effect btn-label waves-light" onClick={() => { handleClickAddByStudent("") }} data-toggle="modal">
                                <i className="bx bx-plus label-icon"></i> Berdasarkan Siswa
                            </button>
                        </Col>
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={""}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={add_by_class_modal}
                    toggle={() => {
                        handleClickAddByClass()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Tambah Berdasarkan Kelas
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("add_by_class")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddByClassType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div style={{}}>
                                {/* Bagian Atas */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "110px" }}>Tahun Pelajaran</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <span style={{ marginLeft: "5px" }}>{costTypeData.school_year}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                                    <span style={{ width: "110px" }}>Tipe Biaya</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <span style={{ marginLeft: "5px" }}>{costTypeData.type}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                                    <span style={{ width: "110px" }}>Nama Biaya</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <strong style={{ marginLeft: "5px" }}>{costTypeData.cost_name}</strong>
                                </div>
                                <hr />
                                <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                    <span style={{ width: "150px" }}>Kelas</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <span style={{ width: "100%", marginLeft: "5px" }}>
                                        <Input
                                            type="select"
                                            name="class_id"
                                            id="class_id"
                                            value={validationAddByClassType.values.class_id || ''}
                                            onChange={(e) => handleChangeClass(e, "add_by_class")}
                                            style={{ width: '100%', height: '35px', textAlign: 'left' }}
                                            required
                                        >
                                            <option value="">Pilih Kelas</option>
                                            {list_class.map((classes, index) => (
                                                <option value={classes.id} key={index}>{classes.class_name}</option>
                                            ))}
                                        </Input>
                                    </span>
                                </div>
                                <hr />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Tarif Non Bulanan (Rp.)</Label>
                                <Input
                                    type="text"
                                    name="rates"
                                    id="rates"
                                    value={validationAddByClassType.values.rates}
                                    onChange={(e) => handleChangeWithRupiah(e, "add_by_class")}
                                />
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">Keterangan</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    value={validationAddByClassType.values.description}
                                    onChange={validationAddByClassType.handleChange}
                                />
                            </div>

                            <div className="mb-3" hidden={
                                localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                !(
                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                )
                            }>
                                <Label className="form-label">Bagian</Label>
                                <Input
                                    type="select"
                                    name="section_id"
                                    id="section_id"
                                    value={validationAddByClassType.values.section_id}
                                    onChange={validationAddByClassType.handleChange}
                                    disabled={
                                        localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                    }
                                    style={{
                                        backgroundColor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'lightgray'
                                                : 'white', // Set the color based on whether the input is disabled
                                        cursor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'not-allowed'
                                                : 'default', // Set the cursor style based on whether the input is disabled
                                    }}
                                    required
                                >
                                    {localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                        ? (
                                            <div></div>
                                        ) : (
                                            <option value="">Select section</option>
                                        )
                                    }
                                    {get_section.map((section, index) => (
                                        <option value={section.id} key={index}>{section.section_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("add_by_class")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal >
                <Modal
                    isOpen={add_by_student_modal}
                    toggle={() => {
                        handleClickAddByStudent()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Tambah Berdasarkan Siswa
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("add_by_student")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddByStudentType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div style={{}}>
                                {/* Bagian Atas */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "110px" }}>Tahun Pelajaran</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <span style={{ marginLeft: "5px" }}>{costTypeData.school_year}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                                    <span style={{ width: "110px" }}>Tipe Biaya</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <span style={{ marginLeft: "5px" }}>{costTypeData.type}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                                    <span style={{ width: "110px" }}>Nama Biaya</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <strong style={{ marginLeft: "5px" }}>{costTypeData.cost_name}</strong>
                                </div>
                                <hr />
                                <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                    <span style={{ width: "150px" }}>Kelas</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <span style={{ width: "100%", marginLeft: "5px" }}>
                                        <Input
                                            type="select"
                                            name="class_id"
                                            id="class_id"
                                            value={validationAddByStudentType.values.class_id || ''}
                                            onChange={(e) => handleChangeClass(e, "add_by_student")}
                                            style={{ width: '100%', height: '35px', textAlign: 'left' }}
                                            required
                                        >
                                            <option value="">Pilih Kelas</option>
                                            {list_class.map((classes, index) => (
                                                <option value={classes.id} key={index}>{classes.class_name}</option>
                                            ))}
                                        </Input>
                                    </span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                    <span style={{ width: "150px" }}>Nama Siswa</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <strong style={{ width: "100%", marginLeft: "5px" }}>
                                        <Input
                                            type="select"
                                            name="student_id"
                                            id="student_id"
                                            value={validationAddByStudentType.values.student_id || ''}
                                            onChange={(e) => handleChangeStudent(e, "add_by_student")}
                                            style={{ width: '100%', height: '35px', textAlign: 'left' }}
                                            required
                                        >
                                            <option value="">Pilih Siswa</option>
                                            {student_data.map((data, index) => (
                                                <option value={data.id} key={index}>{data.registration_no} - {data.student_name}</option>
                                            ))}
                                        </Input>
                                    </strong>
                                </div>
                                <hr />
                            </div>
                            <Label className="form-label">Tarif</Label>
                            <div className="mb-3 row align-items-center">
                                <span htmlFor="post_id" className="col-sm-5 col-form-label">Tarif Non Bulanan (Rp.)</span>
                                <div className="col-sm-7">
                                    <Input
                                        type="text"
                                        name="rates"
                                        id="rates"
                                        value={validationAddByStudentType.values.rates}
                                        onKeyDown={(e) => handleAllMonthKeyDown(e, "add_by_student")}
                                        onChange={(e) => handleChangeWithRupiah(e, "add_by_student")}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Keterangan</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    value={validationAddByStudentType.values.description}
                                    onChange={validationAddByStudentType.handleChange}
                                />
                            </div>
                            <div className="mb-3" hidden={
                                localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                !(
                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                )
                            }>
                                <Label className="form-label">Bagian</Label>
                                <Input
                                    type="select"
                                    name="section_id"
                                    id="section_id"
                                    value={validationAddByStudentType.values.section_id}
                                    onChange={validationAddByStudentType.handleChange}
                                    disabled={
                                        localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                    }
                                    style={{
                                        backgroundColor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'lightgray'
                                                : 'white', // Set the color based on whether the input is disabled
                                        cursor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'not-allowed'
                                                : 'default', // Set the cursor style based on whether the input is disabled
                                    }}
                                    required
                                >
                                    {localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                        ? (
                                            <div></div>
                                        ) : (
                                            <option value="">Select section</option>
                                        )
                                    }
                                    {get_section.map((section, index) => (
                                        <option value={section.id} key={index}>{section.section_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("add_by_student")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal >
                <Modal
                    isOpen={edit_modal}
                    toggle={() => {
                        handleClickEdit()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Edit
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("edit")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationEditType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div style={{}}>
                                {/* Bagian Atas */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ width: "110px" }}>Tahun Pelajaran</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <span style={{ marginLeft: "5px" }}>{costTypeData.school_year}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                                    <span style={{ width: "110px" }}>Tipe Biaya</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <span style={{ marginLeft: "5px" }}>{costTypeData.type}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                                    <span style={{ width: "110px" }}>Nama Biaya</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <strong style={{ marginLeft: "5px" }}>{costTypeData.cost_name}</strong>
                                </div>
                                <hr />
                                <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                    <span style={{ width: "120px" }}>N I S</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <span style={{ marginLeft: "5px" }}>{edit_cost_monthly.registration_no}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                    <span style={{ width: "120px" }}>Nama Siswa</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <strong style={{ marginLeft: "5px" }}>{edit_cost_monthly.student_name}</strong>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                    <span style={{ width: "120px" }}>Kelas Akhir</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <span style={{ marginLeft: "5px" }}>{edit_cost_monthly.class_name}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                    <span style={{ width: "120px" }}>Status</span>
                                    <span style={{ marginLeft: "5px" }}>:</span>
                                    <span style={{ marginLeft: "5px" }}>{getStatusBadge(edit_cost_monthly.is_active)}</span>
                                </div>
                                <hr />
                            </div>
                            <Label className="form-label">Tarif</Label>
                            <div className="mb-3 row align-items-center">
                                <span htmlFor="post_id" className="col-sm-5 col-form-label">Tarif Non Bulanan (Rp.)</span>
                                <div className="col-sm-7">
                                    <Input
                                        type="text"
                                        name="rates"
                                        id="rates"
                                        value={validationEditType.values.rates}
                                        onKeyDown={(e) => handleAllMonthKeyDown(e, "edit")}
                                        onChange={(e) => handleChangeWithRupiah(e, "edit")}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Keterangan</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    value={validationEditType.values.description}
                                    onChange={validationEditType.handleChange}
                                />
                            </div>
                            <div className="mb-3" hidden={
                                localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                !(
                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                )
                            }>
                                <Label className="form-label">Bagian</Label>
                                <Input
                                    type="select"
                                    name="section_id"
                                    id="section_id"
                                    value={validationEditType.values.section_id}
                                    onChange={validationEditType.handleChange}
                                    disabled={
                                        localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                    }
                                    style={{
                                        backgroundColor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'lightgray'
                                                : 'white', // Set the color based on whether the input is disabled
                                        cursor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'not-allowed'
                                                : 'default', // Set the cursor style based on whether the input is disabled
                                    }}
                                    required
                                >
                                    {localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                        ? (
                                            <div></div>
                                        ) : (
                                            <option value="">Select section</option>
                                        )
                                    }
                                    {get_section.map((section, index) => (
                                        <option value={section.id} key={index}>{section.section_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("edit")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal >
            </div >
        </React.Fragment >
    )
};

export default SettingCostStudentNonMonthly;
