import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const get_school_data = (section_id) => {
    return axiosApi
        .get(API_URL + `school_data?section_id=${section_id}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const update_school_data = (params, bodyParam) => {
    return axiosApi
        .put(API_URL + `school_data/${params}`, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const update_change_password = (params, bodyParam) => {
    return axiosApi
        .put(API_URL + `change_password/${params}`, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const system = {
    get_school_data,
    update_school_data,
    update_change_password
};

export default system;